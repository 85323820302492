import React, { useEffect, useState } from 'react';
import Demoformhome from './Demoformhome';
import visa_footer from '../Images/Footer/Visa.png'
import mastercart_footer from '../Images/Footer/Mastercard.png'
import paypal_footer from '../Images/Footer/PayPal.png'
import applepay_footer from '../Images/Footer/ApplePay.png'
import googlepay_footer from '../Images/Footer/GooglePay.png'
import discover_footer from '../Images/Footer/Discover.png'
import maestro_footer from '../Images/Footer/Maestro.png'

const Footercart = () => {

    return (
        <>
            <div className="container-fluid justify-content-center">
                <div className="row justify-content-center d-none d-md-block" >
                    <div className="row  justify-content-center " style={{ borderTop: '0.5px solid #9FAFA4' }}>
                        <div className="col-md-10 col-sm-10 justify-content-center copyright-mobile-footer text-end" style={{ color: '#FBFBFB', fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px' }}>
                            <div className="row justify-content-between mt-2 mb-2 mb-md-2">
                                <div className="col-md-6 col-sm-5 copyright-mobile-footer text-start " >
                                    <span className='footer-copyright_cart'> @ All Copyright Reserved by Rashail Agro 2024.</span>
                                </div>
                                <div className="col-md-6 col-sm-5 copyright-mobile-footer text-end " >
                                    <img src={visa_footer} alt="closed_img" className='closed_img_cart' />
                                    <img src={mastercart_footer} alt="closed_img" className='closed_img_cart ' />
                                    <img src={paypal_footer} alt="closed_img" className='closed_img_cart ' />
                                    <img src={applepay_footer} alt="closed_img" className='closed_img_cart ' />
                                    <img src={googlepay_footer} alt="closed_img" className='closed_img_cart ' />
                                    <img src={discover_footer} alt="closed_img" className='closed_img_cart ' />
                                    <img src={maestro_footer} alt="closed_img" className='closed_img_cart' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* for mobile view --------------- */}
                <div className="row justify-content-center d-block d-md-none" >
                    <div className="row  justify-content-center " style={{ borderTop: '0.5px solid #9FAFA4' }}>
                        <div className="col-md-10 col-sm-10 justify-content-center copyright-mobile-footer text-end" style={{ color: '#FBFBFB', fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px' }}>
                            <div className="row justify-content-between mt-2 mb-2 mb-md-2">
                                <div className="col-md-12 col-sm-5 copyright-mobile-footer text-start " >
                                    <span className='footer-copyright_cart'> @ All Copyright Reserved by Rashail Agro 2024.</span>
                                </div>
                                <div className="col-md-12 col-sm-5 copyright-mobile-footer text-start " >
                                    <img src={visa_footer} alt="closed_img" className='closed_img' />
                                    <img src={mastercart_footer} alt="closed_img" className='closed_img ' />
                                    <img src={paypal_footer} alt="closed_img" className='closed_img ' />
                                    <img src={applepay_footer} alt="closed_img" className='closed_img ' />
                                    <img src={googlepay_footer} alt="closed_img" className='closed_img ' />
                                    <img src={discover_footer} alt="closed_img" className='closed_img ' />
                                    <img src={maestro_footer} alt="closed_img" className='closed_img ' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footercart;
