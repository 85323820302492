import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Prices, Shipping } from '../Constants/Homedata';
import { useCallback } from "react";
import useRazorpay from 'react-razorpay';
import { weborder_url } from '../Constants/urls';
import { clearCart } from '../Redux/cartSlice';
import { useDispatch } from 'react-redux';


const Buynowformcart = ({ onSubmit, productName, Price }) => {
    const dispatch = useDispatch();
    const targetRef = useRef(null);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [Razorpay, isLoaded] = useRazorpay();
    const [formData, setFormData] = useState({
        user_name: '',
        user_phone_no: '',
        order_quantity: selectedQuantity,
        product: productName,
        payment_status: 'Pending',
        address: '',
        product_price: Price,
        shipping_price: Shipping,
        user_paid_amount: selectedQuantity * Price + Shipping,
    });



    const notify = (data) => toast(data);
    const [errors, setErrors] = useState({
        user_name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const value = formData[key];

            if (typeof value === 'string' && value.trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await fetch(weborder_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify(formData),
            });

            // Inside the try block of handleSubmit function
            if (response.ok) {
                const responseData = await response.json();
                const orderId = responseData.id; // Capture the ID
                setFormData((prevData) => ({
                    ...prevData,
                    order_id: orderId, // Assuming your response has an 'id' field
                }));
                // notify('Form submitted successfully');
                handlePayment(orderId); // Pass the orderId to handlePayment
                // setFormData({
                //     user_name: '',
                //     phone_number: '',
                //     email: '',
                //     address: ''
                // });
            }
            else {
                notify('Error submitting form');
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const handlePayment = useCallback(async (orderId) => {
        try {
            const { user_name, address } = formData;
            const options = {
                key: 'rzp_live_0Kp41Ew4kT3GKI',
                amount: selectedQuantity * Price * 100 + Shipping * 100,
                currency: 'INR',
                name: 'Rashail Agro',
                description: `${productName}-Purchase order`,
                image: 'https://rashailagro.in/static/media/Group%20107.33d025a2c0d19d097346.webp',
                handler: async (response) => {
                    // console.log(response);
                    try {
                        const token = '228701687f8db711593cf986b7cbe995d1639da3';
                        const apiResponse = await fetch(`${weborder_url}${orderId}`, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Token ${token}`,
                            },
                            body: JSON.stringify({
                                // payment_status: response.razorpay_payment_status,
                                payment_status: response.razorpay_payment_status || 'Completed',
                                reference_id: response.razorpay_order_id,
                                transaction_id: response.razorpay_payment_id,
                                user_paid_amount: selectedQuantity * Price + Shipping,
                            }),
                        });

                        if (apiResponse.ok) {
                            const responseData = await apiResponse.json();
                            // console.log('Payment successful, received ID:', responseData.id)

                            setFormData((prevData) => ({
                                ...prevData,
                                payment_status: response.razorpay_payment_status,
                            }));
                            dispatch(clearCart());
                            notify('Payment successful');

                        } else {
                            console.error('Error submitting payment details');
                            notify('Error submitting payment details');
                        }
                    } catch (error) {
                        console.error('Error submitting payment details:', error);
                        notify('Error submitting payment details');
                    }
                },
                prefill: {
                    name: user_name,
                },
                notes: {
                    address: address,
                },
                theme: {
                    color: '#3399cc',
                },
            };
            const rzpay = new Razorpay(options);
            rzpay.open();
        } catch (error) {
            console.error('Error in handlePayment:', error);
            notify('Error in handlePayment');
        }
    }, [Razorpay, selectedQuantity, Price, formData]);



    useEffect(() => {
        if (isLoaded && formData.payment_id) {
            handlePayment();
        }
    }, [isLoaded, formData.payment_id, handlePayment]);

    const productNames = JSON.parse(productName);

    return (
        <div className=''>
            <form
                onSubmit={handleSubmit}
            >
                <div className='row'>
                    <div className='col-md-6 '>
                        <h4 className='text-start billing_details'>Billing Details</h4>
                        <div class="mb-2 text-start">
                            <label for="exampleInputtext" class="form-label ">Full Name</label>
                            <input type="text"
                                class="form-control"
                                name="user_name"
                                value={formData.user_name}
                                onChange={handleChange}
                            />
                            {errors.user_name && <div className="error-message text-end">{errors.user_name}</div>}
                        </div>
                        <div class="mb-2 text-start">
                            <label for="exampleInputphone" class="form-label ">Phone Number / Whatsapp</label>
                            <input
                                type="number"
                                class="form-control"
                                name="user_phone_no"
                                value={formData.user_phone_no}
                                onChange={handleChange}
                            />
                            {errors.user_phone_no && <div className="error-message text-end">{errors.user_phone_no}</div>}
                        </div>
                        {/* <div class="mb-2 text-start">
                            <label for="quantityInput" class="form-label">Quantity</label>
                            <select class="form-control" id="quantityInput"
                                value={selectedQuantity}
                                onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}>
                                <option value="1" selected>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div> */}
                        <div class="mb-2 text-start">
                            <label for="floatingTextarea">Address</label>
                            <textarea
                                class="form-control"
                                id="floatingTextarea"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            ></textarea>
                            {errors.address && <div className="error-message text-end">{errors.address}</div>}
                        </div>
                    </div>
                    <div className='col-md-6  align-self-end'>
                        <h4 className='text-start billing_details'>Order Details</h4>
                        <div className="row product-info ">
                            <div className='d-flex mx-auto'>
                                <div className="col-md-6 d-flex flex-column align-items-stretch">
                                    {productNames.map((product, index) => (
                                        <div key={index}>
                                            <div className='text-start flex-grow-1 align-items-stretch'>{product.name} -{product.quantity} ,</div>
                                        </div>
                                    ))}
                                    <p className='text-start buynow_productheading mt-2 flex-grow-0'>Shipping</p>
                                    <p className='text-start buynow_productheading flex-grow-0'><strong>Total</strong></p>
                                </div>
                                <div className="col-md-6 d-flex flex-column align-items-stretch">
                                    <p  className='text-start mx-5 buynow_productheading flex-grow-1 align-items-stretch' >₹ {selectedQuantity * Price}</p>
                                    <p className='text-start mx-5 buynow_productheading flex-grow-0'><strong>Free</strong></p>
                                    <p className='text-start mx-5 buynow_productheading flex-grow-0'><strong>₹ {selectedQuantity * Price + Shipping}</strong></p>
                                </div>
                            </div>

                        </div>
                        <div className="mb-5 mt-5 text-end ">
                            <button
                                type="submit"
                                className="btn btn-secondary "
                                style={{ backgroundColor: "#2FB95D", border: "none", width: "100%" }}
                            // onClick={handlePayment}
                            >
                                Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Buynowformcart
