import React, { useEffect, useRef, useState } from 'react'
import { IOTdevices_shop, NewArrivals_shop, Topproduct_shop, Topproductslider_shop, bottomshop_data, customerreview, recentlyview_shop } from '../Constants/Shopdata'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import aboutrashail_shop from '../Images/Shop/aboutrashail_shop.png'
import axios from 'axios';
import { producturl } from '../Constants/urls';
import { useNavigate } from 'react-router-dom';
import Buynowform from '../Components/Buynowform';
import { Modal } from 'react-bootstrap';
import Demoform from '../Components/Demoform';
import Footer from '../Components/Footer';



const Shop = ({ searchTerm, showFooter }) => {
    const [currentSlide, setCurrentSlide] = useState();
    const [loading, setLoading] = useState(true);
    const [product, setproduct] = useState();
    const [Price, setPrice] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [productName, setproductName] = useState(null);
    const sliderRef = useRef(null);
    const sliderRef_mobile = useRef(null);


    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleModalOpen1 = (product) => {
        setproductName(product.product_name);
        setShowModal1(true);
    };
    const handleModalClose1 = () => {
        setShowModal1(false);
    };
    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        afterChange: (index) => {
            setCurrentSlide(index);
        },
        responsive: [
            {
                breakpoint:768,
                settings: {
                    slidesToShow: 1,
                },
            },

        ],
    };
    const sliderSettings_mobile = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        afterChange: (index) => {
            setCurrentSlide(index);
        },
        responsive: [
            {
                breakpoint: 542,
                settings: {
                    slidesToShow: 1,
                },
            },

        ],
    };

    const getNextSlideIndex = (current, total) => {
        return (current + 1) % total;
    };
    const sliderRef3 = useRef(null);
    const sliderSettings3 = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
            {
                breakpoint: 542,
                settings: {
                    slidesToShow: 1,
                },
            },

        ],
    };
    const sliderRef2 = useRef(null);
    const sliderSettings2 = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1266,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                },
            },

        ],
    };


    const handleBuyNow = (product) => {
        // setSelectedProduct(product);
        setproductName(product.product_name);
        setPrice(product.product_price);
        handleModalOpen();
    };

    const fetchData = async (page = 1) => {
        try {
            //   setLoading(true);
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(
                producturl,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            // console.log(response);
            setproduct(response.data.results);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const navigate = useNavigate();
    const toComponentB = (product) => {
        navigate(`/shop/${encodeURIComponent(product.slug)}/`, { state: { product } });
    };

    const filteredProducts = product && product.filter((product) =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filterTopproduct_shop = Topproduct_shop && Topproduct_shop.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <div className='container-fluid mb-15'>
                <div className="row justify-content-center mx-auto mt-4 mb-4">
                    <div className="col-md-12 d-flex topproduct_shop">
                        {Topproduct_shop.length > 0 ? (
                            <>
                                {Topproduct_shop.map((data) => (
                                    <div className='text-center justify-content-center mx-auto product-item' key={data.id}>
                                        <div style={{
                                            width: '100px', // Fixed width
                                            height: '100px', // Fixed height
                                            margin: '0 auto', // Center align horizontally
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            overflow: 'hidden' // Ensure content within fixed size
                                        }}>
                                            <img src={data.img} alt="closed_img" style={{
                                                maxWidth: '100%', // Ensure image does not exceed container 
                                                maxHeight: '100%', // Ensure image does not exceed container 
                                                objectFit: 'contain', // Maintain aspect ratio and fit within 
                                                padding: "10px"
                                            }} />
                                        </div>
                                        <p className='text-center paraheading_shop_top'>{data.name}</p>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>No products found </p>
                        )}
                    </div>
                </div>
                <div className="row justify-content-center  mt-4 mb-4 no-padding ">
                    <div className="col-md-12 text-center mt-3 position-relative">
                        <Slider ref={sliderRef3} {...sliderSettings3}>
                            {Topproductslider_shop.map((data, index) => (
                                <div className='slider-item' key={data.id}>
                                    <img src={data.img} alt="product_img" className='product_image' style={{ width: "100%" }} />
                                </div>
                            ))}
                        </Slider>
                        <button className="slider-button slider-button-left_slider" onClick={() => sliderRef3.current.slickPrev()}>
                            <i className="fa fa-chevron-left" />
                        </button>
                        <button className="slider-button slider-button-right_slider" onClick={() => sliderRef3.current.slickNext()}>
                            <i className="fa fa-chevron-right" />
                        </button>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop_withoutscroll">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='mx-2'>
                            <h4 className='text-start  mb-2 shopheading'>Best Deals On IoT Devices</h4>
                        </div>
                        <div className='mx-2'>
                            <button className="slider-button_review_newarriwal" >
                                <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-3 position-relative" style={{ overflowX: "hidden" }}>
                        <div className="testimonial-slider-container" >
                            <Slider ref={sliderRef2} {...sliderSettings2}>
                                {product && product.map((product, index) => (
                                    <div key={index} className="testimonial-slide" >
                                        <div className="card custom-card-style_blog mx-auto" style={{ width: "14rem" }}>
                                            <div className='card_blog_first' onClick={() => { toComponentB(product); }} style={{ cursor: "pointer" }}>
                                                <img src={product?.product_images[0]} className="card-img-top" alt="..."
                                                    style={{
                                                        height: '150px',
                                                        display: 'block',
                                                        margin: '0 auto',
                                                        objectFit: "contain",
                                                        padding: "20px"
                                                    }}
                                                />
                                                <div className="card-body text-start" onClick={() => { toComponentB(product); }} style={{ cursor: "pointer" }}>
                                                    <span className='shop_subname'>Rashail</span>
                                                    <h5 className="card-title shop_productname">{product.product_name.slice(0, 10)}...</h5>
                                                    <p className="card-text topdeal_shop_price">
                                                        {product.is_enquiry_product ? (
                                                            <p className="card-text text-start viewproduct_shop" style={{ height: "24px" }}>
                                                                View Product
                                                            </p>
                                                        ) : (
                                                            <p className="card-text text-start" style={{ height: "24px" }}>
                                                                <span className='fullprice_product'>
                                                                    <small style={{ textDecoration: "line-through" }}>
                                                                        ₹
                                                                        {product.product_price + (product.product_price * (product.product_discount / 100))}
                                                                    </small>
                                                                </span>
                                                                <span className='actualprice_shop mx-2'>
                                                                    ₹ {product.product_price}
                                                                </span>

                                                            </p>

                                                        )}
                                                    </p>

                                                </div>
                                            </div>
                                            {product.is_enquiry_product ? (
                                                <span
                                                    className='readmore_blog text-center mt-2'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleModalOpen1(product)}
                                                >
                                                    Request a Demo
                                                </span>
                                            ) : (
                                                <span
                                                    className='readmore_blog text-center mt-2'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleBuyNow(product)}
                                                >
                                                    Buy Now
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>

                        <button className="slider-button slider-button-left_iotdevice" onClick={() => sliderRef2.current.slickPrev()}>
                            <i className="fa fa-chevron-left" />
                        </button>
                        <button className="slider-button slider-button-right_iotdevice" onClick={() => sliderRef2.current.slickNext()}>
                            <i className="fa fa-chevron-right" />
                        </button>
                    </div>
                </div>
                <div className="row justify-content-start mx-auto mt-4 mb-4 topproduct_shop_withoutscroll">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div className='mx-2'>
                            <div className='shopheading' style={{ marginRight: '10px' }}>New Arrivals</div>
                            <div className='topdeal_shop'>(Top Deals)</div>
                        </div>
                        <div className='mx-2'>
                            <button className="slider-button_review_newarriwal">
                                <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                            </button>
                        </div>
                    </div>
                    {/* large screen ------------ */}
                    <div className="col-md-12 justify-content-start text-start mt-3 d-none d-md-block">
                        <div className="row">
                            {NewArrivals_shop.map((data, index) => (
                                <div key={index} className="col-lg col-md-3 col-sm-4 mb-4">
                                    <div className="card justify-content-start text-start custom-card-style_blog mx-auto" style={{ width: "100%", margin: "0 10px" }}>
                                        <div className='card_blog_first'>
                                            <img
                                                src={data.img}
                                                className="card-img-top"
                                                alt="..."
                                                style={{
                                                    height: '150px',
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px"
                                                }}
                                            />
                                            <div className="card-body text-start">
                                                <span className='shop_subname'>{data.subname}</span>
                                                <h5 className="card-title shop_productname">{data.product_name}</h5>
                                                <p className="card-text">
                                                    <span className='fullprice_product'>
                                                        <small style={{ textDecoration: "line-through" }}>
                                                            ₹ {data.price + (data.price * (data.discount / 100))}
                                                        </small>
                                                    </span>
                                                    <span className='actualprice_shop mx-2'>
                                                        ₹ {data.price}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <span className='readmore_blog text-center mt-2' style={{ cursor: "pointer" }}>
                                            Buy Now
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* small screen ------------ */}
                    <div className="col-md-12 justify-content-center text-center mt-3 d-block d-md-none">
                        <div className='row'>
                            {NewArrivals_shop.slice(0, 4).map((data, index) => (
                                <div key={index} className="mx-2 col-6 mx-auto newarriwals_mobile mb-4">
                                    <div className="card justify-content-start custom-card-style_blog mx-auto " >
                                        <div className='card_blog_first'>
                                            <img
                                                src={data.img}
                                                className="card-img-top"
                                                alt="..."
                                                style={{
                                                    height: '150px',
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px"
                                                }}
                                            />
                                            <div className="card-body text-start">
                                                <span className='shop_subname'>{data.subname}</span>
                                                <h5 className="card-title shop_productname">{data.product_name.slice(0, 10)}..</h5>
                                                <p className="card-text">
                                                    <span className='fullprice_product'>
                                                        <small style={{ textDecoration: "line-through" }}>
                                                            ₹ {data.price + (data.price * (data.discount / 100))}
                                                        </small>
                                                    </span>
                                                    <span className='actualprice_shop mx-2'>
                                                        ₹ {data.price}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <span className='readmore_blog text-center mt-2' style={{ cursor: "pointer" }}>
                                            Buy Now
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop_withoutscroll">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <h4 className='shopheading mx-2' style={{ marginRight: '10px' }}>Recently Viewed</h4>
                        </div>
                        <div className='mx-2'>
                            <button className="slider-button_review_newarriwal" >
                                <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                            </button>
                        </div>
                    </div>
                    {/* large screen ------------ */}
                    <div className="col-md-12 justify-content-start text-start mt-3 d-none d-md-block">
                        <div className="row">
                            {recentlyview_shop.map((data, index) => (
                                <div key={index} className="col-lg col-md-3 col-sm-4 mb-4">
                                    <div className="card justify-content-start text-start custom-card-style_blog mx-auto" style={{ width: "100%", margin: "0 10px" }}>
                                        <div className='card_blog_first'>
                                            <img
                                                src={data.img}
                                                className="card-img-top"
                                                alt="..."
                                                style={{
                                                    height: '150px',
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px"
                                                }}
                                            />
                                            <div className="card-body text-start">
                                                <span className='shop_subname'>{data.subname}</span>
                                                <h5 className="card-title shop_productname">{data.product_name}</h5>
                                                <p className="card-text">
                                                    <span className='fullprice_product'>
                                                        <small style={{ textDecoration: "line-through" }}>
                                                            ₹ {data.price + (data.price * (data.discount / 100))}
                                                        </small>
                                                    </span>
                                                    <span className='actualprice_shop mx-2'>
                                                        ₹ {data.price}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <span className='readmore_blog text-center mt-2' style={{ cursor: "pointer" }}>
                                            Buy Now
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Small screen ------------- */}
                    <div className="col-md-12 justify-content-center text-center mt-3 d-block d-md-none">
                        <div className={`row `}>
                            {recentlyview_shop.slice(0, 4).map((data, index) => (
                                <div key={index} className="mx-2 col-6 mx-auto newarriwals_mobile mb-4">
                                    <div className="card justify-content-start custom-card-style_blog mx-auto " >
                                        <div className='card_blog_first'>
                                            <img
                                                src={data.img}
                                                className="card-img-top"
                                                alt="..."
                                                style={{
                                                    height: '150px',
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px"
                                                }}
                                            />
                                            <div className="card-body text-start">
                                                <span className='shop_subname'>{data.subname}</span>
                                                <h5 className="card-title shop_productname">{data.product_name.slice(0, 10)}..</h5>
                                                <p className="card-text">
                                                    <span className='fullprice_product'>
                                                        <small style={{ textDecoration: "line-through" }}>
                                                            ₹ {data.price + (data.price * (data.discount / 100))}
                                                        </small>
                                                    </span>
                                                    <span className='actualprice_shop mx-2'>
                                                        ₹ {data.price}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <span className='readmore_blog text-center mt-2' style={{ cursor: "pointer" }}>
                                            Buy Now
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="justify-content-center mx-auto mt-4 mb-4">
                    <h4 className='text-start shopheading mb-4 mx-5'>Customer Reviews</h4>
                    <div className="col-md-11 col-12 p-0  text-center mt-3 position-relative  mx-auto d-none d-md-block">
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {customerreview.map((data, index) => {
                                const isMiddleCard =
                                    (currentSlide === 0 && index === customerreview.length - 1) ||
                                    index === getNextSlideIndex(currentSlide, customerreview.length);
                                return (
                                    <div key={index} >
                                        <div className={`card card_customerreview ${isMiddleCard ? 'middle-card' : ''}`} style={{ width: "20rem", margin: "auto" }}>
                                            <img src={data.img} className={`card-img-top ${isMiddleCard ? 'middle-img' : ''}`} alt="..."
                                                style={{
                                                    width: "120px",
                                                    height: "120px",
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px",
                                                    borderRadius: "50%"

                                                }}
                                            />
                                            <div className="card-body">
                                                <p className='text-center'>
                                                    {Array.from({ length: 5 }).map((_, starIndex) => {
                                                        const isFilled = starIndex < Math.floor(data.rating);
                                                        const isHalfFilled = starIndex === Math.floor(data.rating) && data.rating % 1 !== 0;

                                                        return (
                                                            <span
                                                                key={starIndex}
                                                                className={`fa fa-star${isFilled ? " checked" : ""}`}
                                                                style={{
                                                                    // fontSize: "30px",
                                                                    backgroundImage: isHalfFilled ? "linear-gradient(to right, rgba(254, 227, 85, 1) 50%, black 50%)" : "none",
                                                                    WebkitBackgroundClip: isHalfFilled ? "text" : "none",
                                                                    color: isHalfFilled ? "transparent" : (isFilled ? "rgba(254, 227, 85, 1)" : "inherit"),
                                                                }}
                                                            ></span>
                                                        );
                                                    })}
                                                </p>
                                                <h5 className={`card-title customer_review_shop_name ${isMiddleCard ? 'middle-customer_review_shop_name' : ''}`}>{data.name}</h5>
                                                <p style={{ height: "40px" }} className={`card-text customer_review_shop_desc ${isMiddleCard ? 'middle-customer_review_shop_desc' : ''}`}>{data.desc.slice(0, 50)}..."</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                        <button className="slider-button_review slider-button-left_testimonial" onClick={() => sliderRef.current.slickPrev()}>
                            <i className="fa fa-chevron-left" style={{ color: "#ffffff" }} />
                        </button>
                        <button className="slider-button_review slider-button-right_testimonial" onClick={() => sliderRef.current.slickNext()}>
                            <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                        </button>
                    </div>
                    {/* For mobile ------------- */}
                    <div className="col-md-11 col-12 p-0  text-center mt-3 position-relative  d-block d-md-none mx-auto">
                        <Slider ref={sliderRef_mobile} {...sliderSettings_mobile}>
                            {customerreview.map((data, index) => {

                                return (
                                    <div key={index} >
                                        <div className="card middle-card" style={{ width: "20rem", margin: "auto" }}>
                                            <img src={data.img} className={`middle-img `} alt="..."
                                                style={{
                                                    width: "120px",
                                                    height: "120px",
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    objectFit: "contain",
                                                    padding: "20px",
                                                    borderRadius: "50%"

                                                }}
                                            />
                                            <div className="card-body">
                                                <p className='text-center'>
                                                    {Array.from({ length: 5 }).map((_, starIndex) => {
                                                        const isFilled = starIndex < Math.floor(data.rating);
                                                        const isHalfFilled = starIndex === Math.floor(data.rating) && data.rating % 1 !== 0;

                                                        return (
                                                            <span
                                                                key={starIndex}
                                                                className={`fa fa-star${isFilled ? " checked" : ""}`}
                                                                style={{
                                                                    // fontSize: "30px",
                                                                    backgroundImage: isHalfFilled ? "linear-gradient(to right, rgba(254, 227, 85, 1) 50%, black 50%)" : "none",
                                                                    WebkitBackgroundClip: isHalfFilled ? "text" : "none",
                                                                    color: isHalfFilled ? "transparent" : (isFilled ? "rgba(254, 227, 85, 1)" : "inherit"),
                                                                }}
                                                            ></span>
                                                        );
                                                    })}
                                                </p>
                                                <h5 className={`card-title middle-customer_review_shop_name `}>{data.name}</h5>
                                                <p style={{ height: "40px" }} className={`card-text customer_review_shop_desc `}>{data.desc.slice(0, 50)}..."</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                        <button className="slider-button_review slider-button-left_testimonial" onClick={() => sliderRef_mobile.current.slickPrev()}>
                            <i className="fa fa-chevron-left" style={{ color: "#ffffff" }} />
                        </button>
                        <button className="slider-button_review slider-button-right_testimonial" onClick={() => sliderRef_mobile.current.slickNext()}>
                            <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                        </button>
                    </div>
                </div>
                <div className="row justify-content-center  text-center mx-auto mb-4 mt-5">
                    <div className="col-md-4 col-sm-6 col-4 d-flex align-items-center" style={{ position: 'relative' }}>
                        <img
                            src={aboutrashail_shop}
                            alt="Fasalam_farming_app"
                            className="img-fluid"
                            style={{ position: 'absolute', objectFit: "cover", right: -15 }}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 aboutrashailagro_shop">
                        <h4 className='text-start rashaillogotext2'>About Rashail Agro</h4>
                        <p className='text-start paraheading_shop_top'>
                            <strong>Seeding Innovation: Rashail Agro Unveils a New Era in Agriculture with IoT Farming Excellence</strong>
                        </p>
                        <p className='text-start paraheading_shop_top'>
                            At <strong>Rashail Agro</strong>, we are committed to revolutionizing the agricultural landscape through cutting-edge technology and the Internet of Things (IoT). Our mission is to empower farmers with intelligent solutions that enhance efficiency, sustainability, and productivity in modern farming practices.
                        </p>
                    </div>
                </div>
                <div className="container mt-4 mb-4">
                    <div className="row justify-content-start mx-2" style={{ borderTop: "1px solid rgba(4, 58, 58, 1)", borderBottom: "1px solid rgba(4, 58, 58, 1)" }}>
                        {bottomshop_data.map((data, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-12 " >
                                <div className="text-center bottomshop_background pb-2 mt-3 mb-3">
                                    <img
                                        src={data.img}
                                        alt="product_image"
                                        className="product_image"
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            objectFit: 'contain',
                                            padding: '10px'
                                        }}
                                    />
                                    <p className="text-center" style={{ color: '#ffffff' }}>
                                        {data.name}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={showModal} onHide={handleModalClose}
                    //  scrollable
                    dialogClassName="custom-modal" >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        <Modal.Title className="text-center">Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-12 col-sm-12 text-center mx-auto">
                            <div className='mt-3'>
                                <Buynowform productName={productName} Price={Price} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModal1} onHide={handleModalClose1}
                //  scrollable
                >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-10 col-sm-10 text-center mx-auto">
                            <div className='mt-3'>
                                <Demoform closeModal1={handleModalClose1} productName={productName} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {showFooter && <Footer />}
        </>

    )
}

export default Shop
