import React, { useEffect, useState } from 'react';
import signup_shop from '../Images/Navbar/signupimg.png';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Modal, Button } from 'react-bootstrap';
import Signup from './Signup';

const Otplogin = ({ handleModalClose, switchToSignup }) => {

    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(15);
    const [isResending, setIsResending] = useState(false);
    const [otpError, setOtpError] = useState('');


    const validateOtp = () => {
        const isValid = /^\d{4}$/.test(otp); // Regex to check if OTP is exactly 4 digits
        setOtpError(isValid ? '' : 'Please enter a 4-digit OTP'); // Set error message if validation fails
        return isValid;
    };
    

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0 && !isResending) {
                setTimer(timer - 1);
            } else if (timer === 0 && !isResending) {
                clearInterval(countdown);
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, isResending]);

    const handleVerify = () => {
        if (validateOtp()) {
            // Call API to verify OTP
            console.log('Valid OTP:', otp);
            handleModalClose(); // Close modal on successful verification
        }
    };

    const handleResendOTP = () => {
        setIsResending(true);
        setTimer(15);
        setTimeout(() => {
            setIsResending(false);
        }, 3000);
    };

    return (
        <div className='container-fluid'>
            <div className="row justify-content-center text-center">
                <div className="col-md-5 signup_background pt-4 pb-4">
                    <h2 className='text-start signup_heading mt-4 mx-4'>Login</h2>
                    <p className='text-start signup_heading_para mx-4'>Welcome back! Let's keep your shopping seamless</p>
                    <img src={signup_shop} alt="Fasalam_farming_app" className="img-fluid text-start" />
                </div>
                <div className="col-md-7 text-center justify-content-center d-flex flex-column pt-4 pb-4" >
                    <div
                        className="btn btn-link position-absolute top-0 end-0 crossicon_background mx-3 mt-4"
                    >
                        <CloseIcon onClick={handleModalClose} style={{ color: "#ffffff" }} />
                    </div>
                    <p className='mt-5 signuppara_shop'>Please Enter the OTP sent to<br /> 9876543210 <span className='signupparacolor_shop'>Change</span></p>
                    <form className='mb-2'>
                        <input id="partitioned" type="text" maxLength="4" value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            error={!!otpError}
                            helperText={otpError} />
                            {otpError && <p className="error-message mt-3 text-center">{otpError}</p>}
                    </form>
                    <button className='button_signupshop mt-3' onClick={handleVerify}>
                        VERIFY
                    </button>
                    {/* Resend OTP section */}
                    <p>Have not Received Code Yet? <span className='signupparacolor_shop' onClick={handleResendOTP} style={{ cursor: "pointer" }}>
                        {isResending ? 'Resending...' : timer > 0 ? `00:${timer < 10 ? '0' + timer : timer}` : 'Resend OTP'}
                    </span></p>
                    <div className='mt-auto'>
                        <p className='mb-2 signupparacolor_shop' onClick={switchToSignup} style={{ cursor: "pointer" }}>New to Rashail Agro? Signup</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otplogin;
