import React from 'react'
import { Topproduct_shop } from '../Constants/Shopdata'
import Footer from '../Components/Footer'

const Ordercancellation = (showFooter) => {
    return (
        <>
            <div className='container-fluid'>
                <div className="row justify-content-center mb-4  d-none d-md-block">
                    <div className="col-md-12 d-flex topproduct_shop" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Irrigation</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Machinery</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Accessories</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Chemicals</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Fertilizers</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Organic</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Automation</p>
                        </div>
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className="row justify-content-center topproduct_shop_withoutscroll" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Irrigation</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Machinery</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Accessories</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Chemicals</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Fertilizers</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Organic</p>
                        </div>
                        <div className="col-3 col-md-2 text-center ">
                            <p className="paraheading_shop_top">Automation</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 ">
                    <div className="col-md-10 p-4 mb-5" style={{ backgroundColor: "#ffffff" }}>
                        <h5 className='text-center shopheading'>Order Cancellation and Return</h5>

                        <h5 className='text-start shopheading'>Cancellation Policy</h5>
                        <div className='termscondition_desc'>
                            The customer can choose to cancel an order any time before it's dispatched. The order cannot be canceled once it’s out for delivery. However, the customer may choose to reject it at the doorstep.
                            The time window for cancellation varies based on different categories and the order cannot be canceled once the specified time has passed.
                        </div>
                        <div className='termscondition_desc'>
                            In some cases, the customer may not be allowed to cancel the order for free, post the specified time and a cancellation fee will be charged. The details about the time window mentioned on the product page or order confirmation page will be considered final.
                        </div>
                        <p className='termscondition_desc'>
                            In case of any cancellation from the seller due to unforeseen circumstances, a full refund will be initiated for prepaid orders.
                        </p>
                        <p className='termscondition_desc'>
                            Rashail Agro Bazaar reserves the right to accept the cancellation of any order. Rashail Agro Bazaar also reserves the right to waive off or modify the time window or cancellation fee from time to time
                        </p>
                        <h5 className='text-start shopheading'>Returns Policy</h5>
                        <p className='termscondition_desc'>
                            Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below
                        </p>
                        <p className='termscondition_desc'>
                            The return policy is divided into three parts; Do read all sections carefully to understand the conditions and cases under which returns will be accepted.
                        </p>
                        <h5 className='text-start shopheading'>Category</h5>
                        <p className='termscondition_subheading text-start'>
                            <span className="checkbox_termcondition"></span>Refund or replacement
                        </p>
                        <div className='termscondition_desc'>
                            This policy enables easy product return requests for customers through the Platform, subject to product validations at the time of pick-up and fraud prevention mechanisms
                        </div>
                        <div className='termscondition_desc'>
                            This policy shall be applicable only if the product was bought when this policy was applicable to the product. If not, the policy provided here shall apply to the order. It is clarified that a customer may only be able to seek a one-time replacement under this Policy, subject to the other terms provided herein.
                        </div>
                        <div className='termscondition_desc'>
                            Exceptions to this policy: Following claims will be covered under the policy provided here and through corresponding validation processes
                        </div>
                        <div className='termscondition_desc'>
                            a.product undelivered
                        </div>
                        <div className='termscondition_desc'>
                            b. product/accessories missing
                        </div>
                        <div className='termscondition_desc'>
                            c. wrong product/accessories delivered
                        </div>
                        <div className='termscondition_desc'>
                            No Returns categories
                        </div>
                        <div className='termscondition_desc'>
                            Some products in the above categories are not returnable due to their nature or other reasons. For all products, the policy on the product page shall prevail.
                        </div>
                        <p className='termscondition_subheading text-start mt-3 mb-3'>
                            <span className="checkbox_termcondition"></span>Replacement only
                        </p>
                        <div className='termscondition_desc'>
                            To help you resolve issues with your product, we may troubleshoot your product either through online tools, over the phone, and/or through an in-person technical visit.
                        </div>
                        <div className='termscondition_desc'>
                            If a defect is determined within the Returns Window, a replacement of the same model will be provided at no additional cost. If no defect is confirmed or the issue is not diagnosed within 7 days of delivery, you will be directed to the warranty partner for resolving any subsequent issues.
                        </div>
                        <p className='termscondition_subheading text-start mt-3 mb-3'>
                            <span className="checkbox_termcondition"></span>Returns Pick-Up and Processing
                        </p>
                        <p className='termscondition_desc'>
                            In case of returns where you would like item(s) to be picked up from a different address, the address can only be changed if pick-up service is available at the new address During pick-up, your product will be checked for the following conditions:
                        </p>
                        <p className='termscondition_desc'>
                            Product name/ image/ brand/ serial number/ article number/ bar code should match and MRP tag should be undetached and clearly visible.
                        </p>
                        <p className='termscondition_desc'>
                            All in-the-box accessories (like remote control, starter kits, instruction manuals, etc.), freebies and combos (if any) should be present.
                        </p>
                        <p className='termscondition_subheading text-start mt-3 mb-3'>
                            <span className="checkbox_termcondition"></span>General Rules for a successful Return
                        </p>
                        <div className='termscondition_desc'>
                            1. In certain cases where the seller is unable to process a replacement for any reason whatsoever, a refund will be given.
                        </div>
                        <div className='termscondition_desc'>
                            2. In cases where a product accessory is found missing/damaged/defective, the seller may either process a replacement of the particular accessory or issue an eGV for an amount equivalent to the price of the accessory, at the seller’s discretion.
                        </div>
                        <div className='termscondition_desc'>
                            3. For products where installation is provided by Rashail Agro Bazaar's service partners, do not open the product packaging by yourself. Rashail Agro Bazaar authorised personnel shall help in unboxing and installation of the product.
                        </div>
                        <div className='termscondition_desc'>
                            4. For Furniture, any product-related issues will be checked by authorised service personnel (free of cost) and attempted to be resolved by replacing the faulty/ defective part of the product. Full replacement will be provided only in cases where the service personnel opines that replacing the faulty/defective part will not resolve the issue.
                        </div>
                        <p className='termscondition_desc mt-3'>
                            Rashail Agro Bazaar holds the right to restrict the number of returns created per order unit, post the evaluation of the product/order defect is undertaken by Rashail Agro Bazaar’s authorized representative.
                        </p>
                    </div>
                </div>
            </div>
            {showFooter && <Footer />}
        </>

    )
}

export default Ordercancellation
