import React from 'react'
import { useNavigate } from 'react-router-dom';
import Oflowcartcolor from '../Images/cart/Oflowcartcolor.png'
import Adresscolorhover from '../Images/cart/AdressColorhover.png'
import Orderreviewimg from '../Images/cart/orderReview.png'
import truckspeed from '../Images/cart/truck-speed-svgrepo-com 1.png'
import Footercart from '../Components/Footercart';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import payment_order from '../Images/cart/payment_orderreview.png'

const Orderreview = () => {

    const products = useSelector((state) => state.cart);
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate("/paymentcomp");
    };


    const totalPrice = () => {
        let total = 0;
        products.forEach((product) => {
            total += product.product_qty * product.product_price;
        });
        return total.toFixed(2);
    };
    return (
        <>
            <div>
                <div className='mb-5 mt-5 px-2' style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div className='col-md-6' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Oflowcartcolor} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p className='mr-2'>Cart</p>
                    </div>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Adresscolorhover} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p>Address</p>
                    </div>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Orderreview} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p>Order Review</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className='deliveryaddressbutton'>4</div>
                        <p>Payment</p>
                    </div>
                </div> */}
                    <div className='col-md-6 deliveryaddressbackground' >
                        <div>
                            <img src={Orderreviewimg} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <h4 className='order_review_head mx-2'>Order Review</h4>
                            <p className='order_review_head_sub mx-2'>Please Check your Order and Shipping Details</p>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <div className="container-fluid">
                                <div className="justify-content-center">
                                    <div className="col-md-12">
                                        <div className="table-responsive" style={{ backgroundColor: "#ffffff", border: "0.4px solid rgba(4, 58, 58, 0.2)" }}>
                                            <table className="contentTable">
                                                <thead className="contentTableHeader">
                                                    <tr className="">
                                                        <th scope="col">S.No</th>
                                                        <th>Images</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="custom-table-body table-gap">
                                                    {products && products.map((product, index) => {
                                                        const serialNumber = index + 1;
                                                        return (
                                                            <tr key={index} className="mt-3" style={{ height: "120px" }}>
                                                                <td className='p-3' scope="row">{serialNumber}</td>
                                                                <td >
                                                                    <img
                                                                        src={product.product_images[0]}
                                                                        className="card-img-top imgproduct rounded img-fluid"
                                                                        alt="..."
                                                                        style={{ maxWidth: '100px', maxHeight: '100px', width: '100%', height: 'auto', objectFit: "contain" }}
                                                                    />
                                                                </td>
                                                                <td className='p-2'>
                                                                    {product.product_name.slice(0, 30)}
                                                                    <br />
                                                                    {product.product_name.slice(30)}
                                                                </td>
                                                                <td className='p-3'> <span className="actualprice_shop_orderreview">₹ {product.product_price}</span></td>
                                                                <td className='p-4'>
                                                                    {product.product_qty}
                                                                </td>
                                                                <td className='p-2 actualprice_shop_orderreview'> ₹{product.product_price * product.product_qty}</td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="container mb-3" >
                                <div className="row justify-content-center " style={{ backgroundColor: "#ffffff" }}>
                                    <div class="col-md-12 ">
                                        <p className="text-start price_detailscart pt-3">Price Details</p>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className='order_review_head_sub' >MRP Total</p>
                                            <p >₹ {totalPrice()}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className='order_review_head_sub'>Additional Discount</p>
                                            <p >₹0</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px dashed  #000" }}>
                                            <p className='order_review_head_sub'>Shipping Charges</p>
                                            <p >₹0</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className='order_review_head_sub'>Total Payable</p>
                                            <p >₹ {totalPrice()}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                            {/* <button className="blue-button-cart"
                                            onClick={() => handleBuyNow()}
                                        >Buy Now</button> */}
                                            {/* <button className="btn buttonfunctionaity mb-md-2 mb-2"
                                            onClick={() => handleBuyNow()}
                                        >
                                            Place Order
                                        </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container mb-5" >
                                <div className="row justify-content-center">
                                    <div class="col-md-12 ">
                                        <span className="cart_paralargeheading text-start">Rashail Automations is a technology platform to facilitate transaction of business. The products and services are offered for sale by the sellers. The user authorizes the delivery personnel to be his agent for delivery of the goods. For details read <a className="tems" style={{ color: "#2FB95D" }}>Terms & Conditions</a> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row justify-content-center mb-5'>
                        <div className='col-md-10'>
                            <h4 className='order_review_head '>Delivery Details</h4>
                            <div className='col-md-7  p-0 ' style={{ backgroundColor: "#ffffff", border: "0.4px solid rgba(4, 58, 58, 0.2)" }}>
                                <div className="container-fluid">
                                    <div className="justify-content-start">
                                        <div className="col-md-12">
                                            <div className='justify-content-between d-flex'>
                                                <div>
                                                    <div className='name_orderreview'>Mukil K</div>
                                                    <small className='email_orderreview'>mukilcool007@gmail.com</small>
                                                    <p>
                                                        S1,Malwa Tower, Near ICICI Bank,<br /> Ashok Nagar, Indore, Madhya <br /> Pradesh, India - 452001.
                                                    </p>
                                                    <span className='pb-3'>+91 9629621164</span>
                                                </div>
                                                <div className='mt-2'>
                                                    <button className="btn button-cart_orderreview d-flex" >
                                                        <FontAwesomeIcon icon={faPen} style={{ color: "#2FB95D", fontSize: "14px",marginTop:"7px" }} /> <span className='mx-2 edit_orderreview'>Edit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row justify-content-center mb-5'>
                        <div className='col-2 pt-2 pb-2' >
                            <form>
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <button className="btn buttonfunctionaity mb-md-2 mb-2" onClick={handleContinue}>Pay ₹{totalPrice()}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container mb-5" >
                    <div className="row justify-content-center">
                        <div class="col-md-12 text-center">
                            <img src={payment_order} alt="best_farming_app_fasalam" className="img-fluid" />
                            <div className="geniuneproduct_cart mt-3">Safe and Secure Payments</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footercart />
        </>

    )
}

export default Orderreview
