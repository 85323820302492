import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { producturl } from '../Constants/urls';

const SuggestionTextField = ({ onInputChange }) => {
    const [value, setValue] = useState('');
    const [suggestionsList, setSuggestionsList] = useState([]);

    const handleInputChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const handleSuggestionsFetchRequested = async ({ value }) => {
        try {
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(producturl, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });

            const productNames = response.data.results.map(product => product.product_name);

            const filteredSuggestions = productNames.filter(
                suggestion => suggestion.toLowerCase().includes(value.toLowerCase())
            );

            setSuggestionsList(filteredSuggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestionsList([]); // Clear suggestions on error
        }
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestionsList([]);
    };

    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => (
        <div className="suggestion-item text-start" >
            {suggestion}
        </div>
    );
    

    const onSuggestionSelected = (event, { suggestionValue }) => {
        setValue(suggestionValue); 
        onInputChange(suggestionValue); 
    };

    const inputProps = {
        value,
        onChange: handleInputChange,
    };

    return (
        <div className="suggestion-container" >
        <Autosuggest
            suggestions={suggestionsList}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected} // Handle suggestion selection
        />
          </div>
    );
};

export default SuggestionTextField;
