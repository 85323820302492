import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { producturl } from '../Constants/urls';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Demoform from '../Components/Demoform';
import Buynowform from '../Components/Buynowform';
import Footer from '../Components/Footer';

const Productdisplay = ({ searchTerm }) => {

    const [product, setproduct] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [Price, setPrice] = useState(null)
    const [productName, setproductName] = useState(null);
    const [loading, setLoading] = useState(true);


    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleModalOpen1 = (product) => {
        setproductName(product.product_name);
        setShowModal1(true);
    };
    const handleModalClose1 = () => {
        setShowModal1(false);
    };

    const handleBuyNow = (product) => {
        // setSelectedProduct(product);
        setproductName(product.product_name);
        setPrice(product.product_price);
        handleModalOpen();
    };

    const fetchData = async (page = 1) => {
        try {
            //   setLoading(true);
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(
                producturl,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            // console.log(response);
            setproduct(response.data.results);
        } catch (error) {
            console.error(error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const filteredProducts = product && product.filter((product) =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const navigate = useNavigate();
    const toComponentB = (product) => {
        navigate(`/shop/${encodeURIComponent(product.slug)}/`, { state: { product } });
    };

    return (
        <>
            <div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='mx-2'>
                            <h4 className='text-start  mb-2 shopheading'>Best Deals On IoT Devices</h4>
                        </div>
                        {/* <div className='mx-2'>
                        <button className="slider-button_review_newarriwal" >
                            <i className="fa fa-chevron-right" style={{ color: "#ffffff" }} />
                        </button>
                    </div> */}
                    </div>
                    <div className="col-md-12 text-center mt-3 position-relative" style={{ overflowX: "hidden" }}>
                        {/* <Slider ref={sliderRef2} {...sliderSettings2}>
                        {IOTdevices_shop.map((data, index) => (
                            <div key={index} className="card-wrapper">
                                <div className="card" style={{ width: "14rem", margin: "0 10px" }}>
                                    <img src={data.img} className="card-img-top" alt="..."
                                        style={{
                                            height: '150px',
                                            display: 'block',
                                            margin: '0 auto',
                                            objectFit: "contain",
                                            padding: "20px"
                                        }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.product_name}</h5>
                                        <p className="card-text">Just ₹ {data.price}</p>
                                    </div>
                                    <div className='buynow_shop text-center mt-2'>Buy Now</div>
                                </div>
                            </div>
                        ))}
                    </Slider> */}
                        <div className="testimonial-slider-container" >
                            <div className="row">
                                {filteredProducts && filteredProducts.map((product, index) => (
                                    <div key={index} className="col-md-3 col-6 mb-3">
                                        <div key={index} className="testimonial-slide"  >
                                            <div className="card  custom-card-style_blog mx-3" style={{ width: "14rem" }}>
                                                <div className='card_blog_first' onClick={() => { toComponentB(product); }} style={{ cursor: "pointer" }}>
                                                    <img src={product?.product_images[0]} className="card-img-top" alt="..."
                                                        style={{
                                                            height: '150px',
                                                            display: 'block',
                                                            margin: '0 auto',
                                                            objectFit: "contain",
                                                            padding: "20px"
                                                        }}
                                                    />
                                                    <div className="card-body text-start" onClick={() => { toComponentB(product); }} style={{ cursor: "pointer" }}>
                                                        <span className='shop_subname'>Rashail</span>
                                                        <h5 className="card-title shop_productname">{product.product_name.slice(0, 15)}...</h5>
                                                        <p className="card-text topdeal_shop_price">
                                                            {product.is_enquiry_product ? (
                                                                <p className="card-text text-start viewproduct_shop" style={{ height: "30px" }}>
                                                                    View Product
                                                                </p>
                                                            ) : (
                                                                <p className="card-text text-start">
                                                                    <span className='fullprice_product'>
                                                                        <small style={{ textDecoration: "line-through" }}>
                                                                            ₹
                                                                            {product.product_price + (product.product_price * (product.product_discount / 100))}
                                                                        </small>
                                                                    </span>
                                                                    <span className='actualprice_shop mx-2'>
                                                                        ₹ {product.product_price}
                                                                    </span>

                                                                </p>

                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                                {product.is_enquiry_product ? (
                                                    <span
                                                        className='readmore_blog text-center mt-2'
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleModalOpen1(product)}
                                                    >
                                                        Request a Demo
                                                    </span>
                                                ) : (
                                                    <span
                                                        className='readmore_blog text-center mt-2'
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleBuyNow(product)}
                                                    >
                                                        Buy Now
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={showModal} onHide={handleModalClose}
                    //  scrollable
                    dialogClassName="custom-modal" >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        <Modal.Title className="text-center">Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-12 col-sm-12 text-center mx-auto">
                            <div className='mt-3'>
                                <Buynowform productName={productName} Price={Price} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModal1} onHide={handleModalClose1}
                //  scrollable
                >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-10 col-sm-10 text-center mx-auto">
                            <div className='mt-3'>
                                <Demoform closeModal1={handleModalClose1} productName={productName} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>

    )
}

export default Productdisplay
