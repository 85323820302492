import React, { useEffect, useState } from 'react'
import signup_shop from '../Images/Navbar/signupimg.png'
import { TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


const Signup = ({ handleModalClose, switchToLogin, switchToOtp }) => {

    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleMobileNumberChange = (event) => {
        const { value } = event.target;
        // Ensure only numeric characters are entered
        if (/^\d*$/.test(value) || value === '') {
            setMobileNumber(value);
        }
    };

    const handleCheckboxChange = (event) => {
        setTermsAccepted(event.target.checked);
    };

    const handleRequestOTP = () => {
        // Validate mobile number format
        if (mobileNumber.trim().length !== 10 || !/^\d{10}$/.test(mobileNumber.trim())) {
            setError('Please enter a valid 10-digit mobile number.');
        } else {
            // Clear any previous error
            setError('');
            // Proceed with OTP request logic (e.g., calling switchToOtp)
            switchToOtp();
        }
    };
    return (
        <div className='container-fluid'>
            <div className="row justify-content-center text-center">
                <div className="col-md-5 signup_background pt-4 pb-4" >
                    <h2 className='text-start  signup_heading mt-4 mx-4'>Sign Up</h2>
                    <p className='text-start signup_heading_para mx-4'>Sign up now and let's get <br /> shopping!</p>
                    <img src={signup_shop} alt="Fasalam_farming_app" className="img-fluid text-start" />
                </div>
                <div className="col-md-7 text-center justify-content-center pt-4 pb-4" >
                    <div
                        className="btn btn-link position-absolute top-0 end-0 crossicon_background mx-3 mt-4"
                    >
                        <CloseIcon onClick={handleModalClose} style={{ color: "#ffffff" }} />
                    </div>
                    <div className='text-start mt-4'>
                        <TextField
                            id='standard-basic'
                            label='Enter Your 10-Digit Mobile Number'
                            variant='standard'
                            type='tel'
                            fullWidth
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            InputLabelProps={{
                                style: { color: 'rgba(159, 175, 164, 1)', fontWeight: '600', fontFamily: 'Mulish' },
                            }}
                            sx={{ mb: 2 }}
                        />
                        {error && <p style={{ color: 'red', fontSize: '0.85rem' }}>{error}</p>}
                    </div>
                    <p className='text-start signuppara_shop mt-3'>
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            name="termsCheckbox"
                            value="terms"
                            checked={termsAccepted}
                            onChange={handleCheckboxChange}
                        />
                        <span className='mx-1'>
                            Continuing by accepting Rashail Agro’s <span className='signupparacolor_shop'>Terms of Use</span> and <span className='signupparacolor_shop'>Privacy Policy</span>
                        </span>
                    </p>
                    {/* <button className='button_signupshop mt-3' 
                    onClick={handleRequestOTP}
                  >
                        CONTINUE
                    </button> */}
                     {!termsAccepted && (
                        <p style={{ color: 'red', fontSize: '0.85rem', marginTop: '10px' }} className='text-start'>
                            Please accept the Terms of Use and Privacy Policy to proceed.
                        </p>
                    )}
                    <button
                        className='button_signupshop mt-3'
                        style={{ cursor: 'pointer' }}
                        onClick={handleRequestOTP}
                        disabled={!termsAccepted} // Disable the button if terms are not accepted
                    >
                        CONTINUE
                    </button>
                    <button className='button_signupexistinguser mt-3' onClick={switchToLogin}>
                        Existing User? Login
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Signup
