import React from 'react'
import { useNavigate } from 'react-router-dom';
import Oflowcartcolor from '../Images/cart/Oflowcartcolor.png'
import Adresscolorhover from '../Images/cart/AdressColorhover.png'
import deliveryaddress from '../Images/cart/deliveryadress.png'
import truckspeed from '../Images/cart/truck-speed-svgrepo-com 1.png'
import Footercart from '../Components/Footercart';
import { useSelector } from 'react-redux';


const Deliveryadress = () => {

    const products = useSelector((state) => state.cart);
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate("/orderreview");
    };


    const totalPrice = () => {
        let total = 0;
        products.forEach((product) => {
            total += product.product_qty * product.product_price;
        });
        return total.toFixed(2);
    };
    return (
        <>
            <div>
                <div className='mb-5 mt-5 px-2' style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div className='col-md-6' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Oflowcartcolor} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p className='mr-2'>Cart</p>
                    </div>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Adresscolorhover} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p>Address</p>
                    </div>
                    <div style={{ flex: 1 }} className="step-container">
                        <div >
                            <img src={Orderreview} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                        <p>Order Review</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className='deliveryaddressbutton'>4</div>
                        <p>Payment</p>
                    </div>
                </div> */}
                    <div className='col-md-6 deliveryaddressbackground ' >
                        <div>
                            <img src={deliveryaddress} alt="best_farming_app_fasalam" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row justify-content-center mb-5 mx-auto'>
                        <div className='col-md-10 p-0'>
                            <h4 className='order_review_head'>Delivery Address</h4>
                            <p className='order_review_head_sub'>Please Fill the Details below</p>
                        </div>
                        <div className='col-md-6 col-12 pt-3 pb-3 mb-3' style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                            <form>
                                <div className="mb-2 row">
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="form-label">Full Name
                                            {/* <span className='starcolor mx-1'>*</span> */}
                                        </label>
                                        <input type="text" className="form-control inputbackground_delivery" id="name" aria-describedby="emailHelp" placeholder='Mukil k' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email" className="form-label"></label>
                                        <input type="email" className="form-control inputbackground_delivery mt-2" id="email" placeholder='Email' />
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="form-label"></label>
                                        <input type="text" className="form-control inputbackground_delivery mt-2" id="name" aria-describedby="emailHelp" placeholder='Phone Number' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="text" className="form-label"></label>
                                        <input type="text" className="form-control inputbackground_delivery mt-2" id="email" placeholder='PIN Code' />
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <div className="col-md-12">
                                        <div class="mb-3">
                                            <label for="exampleFormControlTextarea1" class="form-label"></label>
                                            <textarea class="form-control mt-2 inputbackground_delivery" id="exampleFormControlTextarea1" rows="3" placeholder='Shipping Address'></textarea>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb-2 row">
                                    <div className="col-md-6">
                                        <label htmlFor="city" className="form-label">City<span className='starcolor mx-1'>*</span></label>
                                        <input type="text" className="form-control inputbackground_delivery" id="name" aria-describedby="emailHelp" placeholder='Village/Town/City' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="state" className="form-label">
                                            State<span className='starcolor mx-1'>*</span>
                                        </label>
                                        <select
                                            id="state"
                                            className="form-select"
                                        // value={selectedState}
                                        // onChange={handleStateChange}
                                        >
                                            <option value="">Select a State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="mb-2 mt-3 row">
                                    <div className="col-md-12">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                        <span className='checkbox_deliveryadress'> Save Information for next time</span>
                                    </div>

                                </div>
                                {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <button className="btn buttonfunctionaity mb-md-2 mb-2" onClick={handleContinue}>CONTINUE</button>
                                </div> */}
                            </form>
                        </div>
                        <div className='col-md-4'>
                            <div className="container mb-3" >
                                <div className="row justify-content-center " style={{ backgroundColor: "#ffffff" }}>
                                    <div class="col-md-12 ">
                                        <p className="text-start price_detailscart">Price Details</p>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p >MRP Total</p>
                                            <p >₹ {totalPrice()}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p >Additional Discount</p>
                                            <p >₹0</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px dashed  #000" }}>
                                            <p >Shipping Charges</p>
                                            <p >₹0</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p >Total Payable</p>
                                            <p >₹ {totalPrice()}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                            {/* <button className="blue-button-cart"
                                            onClick={() => handleBuyNow()}
                                        >Buy Now</button> */}
                                            {/* <button className="btn buttonfunctionaity mb-md-2 mb-2"
                                            onClick={() => handleBuyNow()}
                                        >
                                            Place Order
                                        </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container mb-5" >
                                <div className="row justify-content-center">
                                    <div class="col-md-12 ">
                                        <span className="cart_paralargeheading text-start">Rashail Automations is a technology platform to facilitate transaction of business. The products and services are offered for sale by the sellers. The user authorizes the delivery personnel to be his agent for delivery of the goods. For details read <a className="tems" style={{ color: "#2FB95D" }}>Terms & Conditions</a> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row justify-content-center mb-5'>
                        <div className='col-2 pt-2 pb-2' >
                            <form>
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <button className="btn buttonfunctionaity mb-md-2 mb-2" onClick={handleContinue}>CONTINUE</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container mb-5" >
                    <div className="row justify-content-center">
                        <div class="col-md-12 text-center">
                            <img src={truckspeed} alt="best_farming_app_fasalam" className="img-fluid" />
                            <div className="geniuneproduct_cart mt-3">Fast and Reliable Delivery Guaranteed!</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footercart />
        </>

    )
}

export default Deliveryadress
