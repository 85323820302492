const base_url = `https://preprod.dashboard.rashailagro.in`;
const base_url1 = `https://prod.rashailagro.in`;
const producturl=`${base_url}/shop/product-view`
const allblog=`${base_url}/website/blog-detail`
const likeurl=`${base_url}/website/blog-like-view/`
const commenturl=`${base_url}/website/blog-comment-view/`
const videourl=`${base_url}/website/web-youtube/`
const weborder_url=`${base_url}/shop/web-order/`
const enquiryurl=`${base_url1}/website/enquiry/`
const carouselurl=`${base_url1}/data/carousel/`

export {
    base_url,
    allblog,
    producturl,
    likeurl,
    commenturl,
    videourl,
    weborder_url,
    enquiryurl,
    carouselurl
  };