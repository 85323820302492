import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../Images/Navbar/Rashail Agro LOGO (1).png'
import navbarshop_logo from '../Images/Navbar/navbarshop_logo.png'
import { Card, Modal, Button } from 'react-bootstrap';
import modelimg from '../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { List, ListItem, ListItemText } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import closed_img from '../Images/Navbar/menu-alt-05-svgrepo-com 1.webp'
import menu from '../Images/Navbar/Menu.webp'
import cart_navbar from '../Images/Navbar/cart_navbar.png'
import login_navbar from '../Images/Navbar/login_navbar.png'
import search_navbar from '../Images/Navbar/search_navbar.png'
import Demoform from './Demoform';
import { useSelector } from 'react-redux';
import { selectTotalCount } from '../Redux/cartSlice';
import { enquiryurl } from '../Constants/urls';
import Demoformhome from './Demoformhome';
import { FiShoppingCart } from "react-icons/fi";
import GoogleTranslateWidget from './GoogleTranslateWidget';
import Signup from './Signup';
import SuggestionTextField from './SuggestionTextField ';
import Loginform from './Loginform';
import Otplogin from './Otplogin';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';


function Navbar({ onSearchChange }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeDrawerLink, setActiveDrawerLink] = useState(location.pathname);
    const products = useSelector((state) => state.cart);
    const cartItemCount = products.length;
    const totalCount = useSelector(selectTotalCount);
    const [searchTerm, setSearchTerm] = useState('')

    console.log(products)
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const theme = useTheme();
    const is768View = useMediaQuery(theme.breakpoints.down('sm'));

    const handleInputChange = (e) => {
        if (e && e.target) {
            const searchTerm = e.target.value;
            console.log(searchTerm)
            onSearchChange(searchTerm);
        }

    };

    const handleSearchChange = (selectedValue) => {
        onSearchChange(selectedValue);
        navigate('/productdisplay');
    };

    const handlecartclick=()=>{
        navigate('/cart/');
    };

    const handleApplyNowClick = () => {
        setShowModal(true);
        setShowApplicationForm(true);
    };

    const isRouteActive = (path) => {
        return location.pathname === path;
    };

    const getLinkClass = (path) => {
        return isRouteActive(path) ? 'gradient-text-navbar' : 'nav-link font-weight-bold';

    };

    const getHoverClass = () => {
        return 'hover-effect';
    };

    const handleLinkClick = () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        if (navbarToggler) {
            navbarToggler.click();
        }
        window.dataLayer.push({ 'event': 'button-click' });
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
        if (open) {
            navigate(activeDrawerLink);
        }
        window.dataLayer.push({ 'event': 'button-click' });
    };


    const isMobile = useMediaQuery('(max-width: 768px)');
    const drawerWidth = 250;

    const handleDownloadClick = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };

    const [showModal, setShowModal] = useState(false);

    // Function to handle opening the modal
    const handleOpenModal = () => {
        setModalType('login');
        setShowModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [modalType, setModalType] = useState('login');

    const handleClose = () => {
        setModalType('login');
    };

    const renderModalContent = () => {
        switch (modalType) {
            case 'login':
                return <Loginform handleModalClose={handleCloseModal} switchToSignup={() => setModalType('signup')} switchToOtp={() => setModalType('otp')} />;
            case 'otp':
                return <Otplogin handleModalClose={handleCloseModal} switchToSignup={() => setModalType('signup')} />;
            case 'signup':
                return <Signup handleModalClose={handleCloseModal} switchToLogin={() => setModalType('login')} switchToOtp={() => setModalType('otp')} />;
            default:
                return null;
        }
    };


    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid mx-auto" style={{ justifyContent: "space-between" }}>
                    {/* {isMobile && (
                        <>
                            <IconButton
                            className='icon_sidebar'
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <img src={closed_img} alt="closed_img" className='closed_img' />
                            </IconButton>
                            <Link className="navbar-brand mx-md-5" to="/" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                <img src={navbarshop_logo} alt="rashail_agro_logo" className='navbar_logo' width={200} />
                            </Link>
                            <span className="link-container" >
                                <Link
                                  
                                    onClick={handleOpenModal}
                                    className={`${getLinkClass('#')} ${getHoverClass()}`}
                                    to="#"
                                >
                                    <img src={login_navbar} alt="closed_img" className="closed_img" />
                                </Link>
                            </span>
                            <span className="link-container" >
                                <Link
                                    onClick={handleLinkClick}
                                    className={`${getLinkClass('/cart/')} ${getHoverClass()}`}
                                    to="/cart/"
                                >
                                    <img src={cart_navbar} alt="closed_img" className="closed_img mx-3" />
                                    {cartItemCount > 0 && (
                                        <span className="cart-count position-absolute top-1 start-90 translate-middle badge rounded-pill bg-danger">
                                            {totalCount}
                                        </span>
                                    )}
                                </Link>
                            </span>
                        </>
                    )} */}
                    {isMobile && (
                        <Grid container alignItems="center" justifyContent="center"> {/* Add justifyContent="center" */}
                            <Grid item xs={8} sm={10}>
                                <Link className="navbar-brand " to="/" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                    <img src={navbarshop_logo} alt="rashail_agro_logo" className='navbar_logo' width={200} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    onClick={handleOpenModal}
                                    className={`${getLinkClass('#')} ${getHoverClass()}`}
                                    to="#"
                                >
                                    <img src={login_navbar} alt="closed_img" className="closed_img" />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    onClick={handleLinkClick}
                                    className={`${getLinkClass('/cart/')} ${getHoverClass()}`}
                                    to="/cart/"
                                >
                                    <img src={cart_navbar} alt="closed_img" className="closed_img mx-3" />
                                    {cartItemCount > 0 && (
                                        <span className="cart-count position-absolute top-1 start-90 translate-middle badge rounded-pill bg-danger">
                                            {totalCount}
                                        </span>
                                    )}
                                </Link>
                            </Grid>
                        </Grid>
                    )}


                    <Link className="navbar-brand mx-md-5 d-none d-md-block" to="/" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                        <img src={navbarshop_logo} alt="rashail_agro_logo" className='navbar_logo' width={200} />
                    </Link>
                    <div className="d-flex  mx-3 justify-content-center">
                        <form className="mx-auto text-center" style={{ width: '360px', position: 'relative' }}>
                            {/* <input
                                className="form-control custom-search shadow-sm px-5"
                                type="search"
                                placeholder="Search for products"
                                aria-label="Search"
                                onChange={handleInputChange}
                                style={{ maxWidth: '100%', width: '100%', paddingRight: '40px', borderRadius: "15px", backgroundColor: "#ECECEC" }}
                            /> */}
                            <div className="suggestions-container">
                                <SuggestionTextField className="form-control custom-search shadow-sm px-5" onInputChange={handleSearchChange} />
                            </div>
                            <img
                                src={search_navbar}
                                alt="search_img"
                                className='search_img'
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '10px',
                                    transform: 'translateY(-50%)',
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer'
                                }}
                            />
                        </form>
                    </div>
                    <div className='mx-4'>
                        <GoogleTranslateWidget />
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto mx-5">
                            <Link
                                onClick={handleOpenModal}
                                className={`${getLinkClass('#')} ${getHoverClass()}`}
                                to="#">
                                <img src={login_navbar} alt="closed_img" className='closed_img mt-1' style={{ width: "26px", height: "26px" }} />
                            </Link>
                            <span className='mt-3' style={{ cursor: "pointer" }}
                                onClick={handleOpenModal}
                            >Login</span>
                            <span className='d-flex mx-4'>
                                <Link
                                    onClick={handleLinkClick}
                                    className={`${getLinkClass('/cart/')} ${getHoverClass()}`}
                                    to="/cart/">
                                    <img src={cart_navbar} alt="closed_img" className='closed_img mt-1' style={{ width: "26px", height: "26px" }} />
                                    {cartItemCount > 0 && <span className="cart-count position-absolute top-1 start-90 translate-middle badge rounded-pill bg-danger">
                                        {totalCount}
                                    </span>}
                                </Link>
                                <span className='mt-3 ' onClick={handlecartclick} style={{cursor:"pointer"}}>Cart</span>
                            </span>

                        </div>
                    </div>
                </div>
            </nav>

            <Modal
                //  show={true} 
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                centered>
                <Modal.Body className="text-center p-0">
                    <div className="col-md-12 col-sm-12 text-center mx-auto">
                        {renderModalContent()}
                    </div>
                </Modal.Body>
            </Modal>
            <SwipeableDrawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                PaperProps={{ style: { width: '100%' } }}
            >

                <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    style={{ position: 'fixed', right: 20, top: 20, zIndex: 1 }}
                    onClick={() => {
                        toggleDrawer(false)();
                        setActiveDrawerLink('/');
                        console.log('Clicked CloseIcon');
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <div className='container-fluid'>
                    <div className='col-md-12 d-flex justify-content-between align-items-center'>
                        <div>
                            <List >
                                <ListItem
                                    button
                                    onClick={() => {
                                        toggleDrawer(false)();
                                        setActiveDrawerLink('/cart/');
                                    }}
                                    component={Link}
                                    to="/cart/"
                                    className={activeDrawerLink === '/cart/' ? 'active-drawer-link' : ''}
                                    style={{ marginBottom: '10px' }}
                                >
                                    {cartItemCount > 0 && <span className="cart-count position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                                        {totalCount}
                                    </span>}
                                    {/* <FiShoppingCart size={30} style={{ color: 'black' }} /> */}
                                    <img src={cart_navbar} alt="closed_img" className='closed_img' />
                                </ListItem>
                            </List>
                        </div>
                        <div>
                            <div className='mt-5'>
                                <img src={menu} alt="menu" className='menu mt-5' />
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    );
}

export default Navbar;
