import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/scroll";
import Gsmmotorcontroller from "./Pages/Gsmmotorcontroller";
import PageNotFound from "./Pages/PageNotFound";
import Cart from "./Pages/Cart";
import { useEffect, useState } from "react";
import Shop from "./Pages/Shop";
import Shopdetails from "./Pages/Shopdetails";
import Shopdetailsnew from "./Pages/Shopdetailsnew";
import Signup from "./Components/Signup";
import SuggestionTextField from "./Components/SuggestionTextField ";
import Productdisplay from "./Pages/Productdisplay";
import Deliveryadress from "./Pages/Deliveryadress";
import OrderReview from "./Pages/Orderreview";
import Orderreview from "./Pages/Orderreview";
import Paymentcomp from "./Pages/Paymentcomp";
import Termscondition from "./Pages/Termscondition";
import Ordercancellation from "./Pages/Ordercancellation";
import Privacypolicy from "./Pages/Privacypolicy";




function App() {

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  return (
    <Router>
      <div>
      </div>
      <Navbar onSearchChange={handleSearchChange}/>
      <Routes>
        {/* <Route exact path="/shop/:slug/" element={<Gsmmotorcontroller />} /> */}
        <Route exact path="/" element={<Shop searchTerm={searchTerm}  showFooter={true}/>} />
        <Route exact path="/cart/" element={<Cart />} />
        <Route exact path="/shop/:slug/" element={<Shopdetails showFooter={true} />} />
        <Route exact path="/shopdetailsnew/" element={<Shopdetailsnew />} />
        <Route exact path="/suggestionTextField/" element={<SuggestionTextField />} />
        <Route exact path="/deliveryadress/" element={<Deliveryadress />} />
        <Route exact path="/orderreview/" element={<Orderreview />} />
        <Route exact path="/paymentcomp/" element={<Paymentcomp />} />
        <Route exact path="/termscondition/" element={<Termscondition  showFooter={true}/>} />
        <Route exact path="/ordercancellation/" element={<Ordercancellation  showFooter={true}/>} />
        <Route exact path="/privacypolicy/" element={<Privacypolicy  showFooter={true}/>} />
        <Route exact path="/productdisplay/" element={<Productdisplay searchTerm={searchTerm}  />} />
        {/* <Route exact path="/signup/" element={<Signup />} /> */}
        <Route path="*" element={<PageNotFound />}
        />
      </Routes>
      <ScrollToTop />
      {/* <Footer /> */}
    </Router>

  );
}

export default App;
