import React from 'react'
import { NewArrivals_shop, bottomshop_data, recentlyview_shop } from '../Constants/Shopdata'
import gardeningtoolk_newarrival from '../Images/Shop/Gardening Toolk.png'
import addtocart_shopdetails from '../Images/Shopdetails/addtocart_shodetails.png'
import Buynow_shopdetails from '../Images/Shopdetails/Buynow_shopdetails.png'


const Shopdetailsnew = () => {
    return (
        <div className='container-fluid'>
            <div className="row mt-md-2 justify-content-center mx-1" style={{ backgroundColor: "#ffffff" }}>
                <div className='col-md-12 col-lg-5 d-flex justify-content-center '>
                    <div className="col-md-12 productimg-productdetails  col-12 text-center" >
                        <div className='text-center selectedimage_resp mx-auto mt-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }} >
                            <img
                                src={gardeningtoolk_newarrival}
                                alt={`Thumbnail`}
                                className="img-fluid image-thumbnail"
                                style={{ width: "388px", border: "0.5px solid rgba(4, 58, 58, 0.5)", padding: "40px" }}
                            />
                        </div>
                        <div className="d-flex mt-5 justify-content-center text-center" style={{ maxWidth: '400px', width: '400px', overflowX: 'scroll', '-ms-overflow-style': 'none', margin: '0 auto' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                {NewArrivals_shop.map((image, index) => (
                                    <div key={index} style={{ minWidth: '120px', maxWidth: '120px', margin: '0 auto' }}>
                                        <img
                                            src={image.img}
                                            alt={`Thumbnail ${index}`}
                                            className="img-fluid image-thumbnail"
                                            style={{ cursor: 'pointer', border: "0.5px solid rgba(4, 58, 58, 0.5)", width: '100%', height: '70px', objectFit: 'contain' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='row d-flex justify-content-center mt-3 mx-5'>
                            <div class=" col-md-12 form-group d-flex align-items-center pt-2 pb-2" style={{ backgroundColor: "#ffffff", border: "0.5px solid rgba(4, 58, 58, 0.8)" }}>
                                <label for="quantity-select" class="mr-3 mx-5">Quantity</label>
                                <select class="" id="quantity-select p-2" required style={{ backgroundColor: "rgb(236, 236, 236)", width: "100%" }}>
                                    <option selected>- Select Quantity -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3 mx-5'>
                            <div className='addtocart_shopdetails' style={{ cursor: "pointer" }}>
                                <span>
                                    <img src={addtocart_shopdetails} alt='' className="img-flui image-thumbnail" />
                                </span>
                                <span className='mx-1' style={{ color: "#2FB95D", fontWeight: "600" }}>
                                    Add to cart
                                </span>
                            </div>
                            <div className='buynow_shopdeatails' style={{ cursor: "pointer" }}>
                                <span>
                                    <img src={Buynow_shopdetails} alt='' className="img-flui image-thumbnail" />
                                </span>
                                <span className='mx-1' style={{ color: "#ffffff", fontWeight: "600" }}>
                                    Buy Now
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-12 col-lg-7 text-start mt-4 mt-md-4">
                    <div className='text-start'>
                        <div>
                            <h4>JINPRI Gardening Tools kit Hand Cultivator, Small Trowel, Garden Fork (Set of 3) Garden Tool Kit (3 Tools)</h4>
                            <span className='in_stock'>In Stock</span>
                            <div>
                                <span className='actualprice_product'>₹300</span> MRP  <span className='fullprice_product'>
                                    <small style={{ textDecoration: "line-through" }}>
                                        ₹
                                        {/* 200 */}
                                        {300 + (300 * (20 / 100))}
                                    </small>
                                </span>
                                <span style={{ color: '#2FB95D' }}> 20%Off</span>
                            </div>
                        </div>
                        <div className='productdetailspara mt-4 mb-3 text-start'>
                            <h4>Specifications</h4>
                            <p className='description_productdetails'>
                                It is used for breaking up earth, digging small holes, especially for planting and weeding, mixing in fertilizer or other additives and transferring plants to pots.
                            </p>
                        </div>
                        <h4>General Info</h4>
                        <div className="row d-flex  mb-2" >
                            <div className='col-md-12 d-flex'>
                                <div className="col-md-10 col-12">
                                    <div className='d-flex justify-content-between'>
                                        <div className="col-md-6  technicaldetailshead ">Brand</div>
                                        <div className=" col-md-6  technicaldetailssubhead">JINPRI</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="technicaldetailshead col-md-6">Model Number</div>
                                        <div className="technicaldetailssubhead col-md-6">Gardening Tools kit Hand Cultivator, Small Trowel, Garden Fork (Set of 3)</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="technicaldetailshead col-md-6">Number of Tools</div>
                                        <div className="technicaldetailssubhead col-md-6">3</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="technicaldetailshead col-md-6">Features</div>
                                        <div className="technicaldetailssubhead col-md-6">THE BEST GARDENING GIFTS: This garden tools set is specifically designed for gardeners and flower growers. The gardening set is the perfect gift for men or women, pensioners or teenagers</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="technicaldetailshead col-md-6">Net Quantity</div>
                                        <div className="technicaldetailssubhead col-md-6">3</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className='mt-4'>About this item</h4>
                        <div className="col-md-10 col-sm-10 text-center">
                            <span>
                                <div className='productdetailspara text-start'>
                                    <ul className='p-0 mx-3'>
                                        <li >Comprehensive Gardening Toolkit for all your gardening needs</li>
                                        <li >Includes durable tools such as trowels, cultivators, pruners, and shears</li>
                                        <li >Designed for both novice enthusiasts and seasoned gardeners</li>
                                        <li >Ergonomic design for comfort and efficiency</li>
                                        <li >Ensure your garden thrives with ease and convenience</li>
                                    </ul>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop">
                <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #00000033", paddingBottom: "10px" }}>
                    <div>
                        <h4 style={{ marginRight: '10px' }}>New Arrivals</h4>
                        <div>(Top Deals)</div>
                    </div>
                    <div className='viewall_shop' >
                        View All
                    </div>
                </div>
                <div className="col-md-12 justify-content-center text-center mt-3 d-flex">
                    {NewArrivals_shop.map((data, index) => (
                        <div key={index} className="testimonial-slide">
                            <div className="card custom-card-style_blog" style={{ width: "14rem", margin: "0 10px" }}>
                                <div className='card_blog_first'>
                                    <img src={data.img} className="card-img-top" alt="..."
                                        style={{
                                            height: '150px',
                                            display: 'block',
                                            margin: '0 auto',
                                            objectFit: "contain",
                                            padding: "20px"
                                        }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.product_name}</h5>
                                        <p className="card-text">Just ₹ {data.price}</p>
                                    </div>
                                </div>
                                <span
                                    className='readmore_blog text-center mt-2'
                                    style={{ cursor: "pointer" }}
                                >
                                    Buy Now
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop">
                <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #00000033", paddingBottom: "10px" }}>
                    <div>
                        <h4 style={{ marginRight: '10px' }}>Recently Viewed</h4>

                    </div>
                    <div className='viewall_shop' >
                        View All
                    </div>
                </div>
                <div className="col-md-12 justify-content-center text-center mt-3 d-flex">
                    {recentlyview_shop.map((data, index) => (
                        <div key={index} className="card-wrapper">
                            <div className="card" style={{ width: "14rem", margin: "0 10px" }}>
                                <img src={data.img} className="card-img-top" alt="..."
                                    style={{
                                        height: '150px',
                                        display: 'block',
                                        margin: '0 auto',
                                        objectFit: "contain",
                                        padding: "20px"
                                    }}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{data.product_name}</h5>
                                    <p className="card-text">Just ₹ {data.price}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="container mt-4 mb-4">
                <div className="row justify-content-start" style={{ borderTop: "1px solid rgba(4, 58, 58, 1)", borderBottom: "1px solid rgba(4, 58, 58, 1)" }}>
                    {bottomshop_data.map((data, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-12" >
                            <div className="text-center bottomshop_background pb-2 mt-5 mb-5">
                                <img
                                    src={data.img}
                                    alt="product_image"
                                    className="product_image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto',
                                        objectFit: 'contain',
                                        padding: '10px'
                                    }}
                                />
                                <p className="text-center" style={{ color: '#ffffff' }}>
                                    {data.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Shopdetailsnew
