import React, { useEffect, useState } from 'react'
import signup_shop from '../Images/Navbar/signupimg.png'
import { TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Otplogin from './Otplogin';
import { Card, Modal, Button } from 'react-bootstrap';
import Signup from './Signup';

const Loginform = ({ handleModalClose, switchToSignup, switchToOtp }) => {
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState('');

    const handleMobileNumberChange = (event) => {
        const { value } = event.target;
        // Ensure only numeric characters are entered
        if (/^\d*$/.test(value) || value === '') {
            setMobileNumber(value);
        }
    };


    const handleRequestOTP = () => {
        // Validate mobile number format
        if (mobileNumber.trim().length !== 10 || !/^\d{10}$/.test(mobileNumber.trim())) {
            setError('Please enter a valid 10-digit mobile number.');
        } else {
            // Clear any previous error
            setError('');
            // Proceed with OTP request logic (e.g., calling switchToOtp)
            switchToOtp();
        }
    };

    const handleCheckboxChange = (event) => {
        setTermsAccepted(event.target.checked);
    };

    const handleModalOpen1 = () => {
        console.log('Opening OTP modal');
        handleModalClose(); // Close the current modal using the provided handler

        // Set a short delay before opening the OTP modal
        setTimeout(() => {
            setShowModal1(true); // Open the OTP modal
        }, 100);
    };


    const handleModalClose1 = () => {
        setShowModal1(false);
    };

    const handleModalOpen2 = () => {
        setShowModal2(true);

    };

    const handleModalClose2 = () => {
        setShowModal2(false);
    };

    return (
        <div className='container-fluid'>
            <div className="row justify-content-center text-center">
                <div className="col-md-5 signup_background pt-4 pb-4" >
                    <h2 className='text-start  signup_heading mt-4 mx-4'>Login</h2>
                    <p className='text-start signup_heading_para mx-4'>Welcome back! Let's keep your shopping seamless</p>
                    <img src={signup_shop} alt="Fasalam_farming_app" className="img-fluid text-start" />
                </div>
                <div className="col-md-7  text-center justify-content-center d-flex flex-column pt-4 pb-4" >
                    <div
                        className="btn btn-link position-absolute top-0 end-0 crossicon_background mx-3 mt-4"
                    >
                        <CloseIcon onClick={handleModalClose} style={{ color: "#ffffff" }} />
                    </div>
                    <div className='text-start mt-4'>
                        <TextField
                            id='standard-basic'
                            label='Enter Your 10-Digit Mobile Number'
                            variant='standard'
                            type='tel'
                            fullWidth
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            InputLabelProps={{
                                style: { color: 'rgba(159, 175, 164, 1)', fontWeight: '600', fontFamily: 'Mulish' },
                            }}
                            sx={{ mb: 2 }}
                        />
                        {error && <p style={{ color: 'red', fontSize: '0.85rem' }}>{error}</p>}
                    </div>
                    <p className='text-start signuppara_shop mt-3'>
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            name="termsCheckbox"
                            value="terms"
                            checked={termsAccepted}
                            onChange={handleCheckboxChange}
                        />
                        <span className='mx-1'>
                            Continuing by accepting Rashail Agro’s <span className='signupparacolor_shop'>Terms of Use</span> and <span className='signupparacolor_shop'>Privacy Policy</span>
                        </span>
                    </p>
                    {/* <button className='button_signupshop mt-3' style={{ cursor: 'pointer' }} onClick={handleRequestOTP}>
                        REQUEST OTP
                    </button> */}
                    {!termsAccepted && (
                        <p style={{ color: 'red', fontSize: '0.85rem', marginTop: '10px' }} className='text-start'>
                            Please accept the Terms of Use and Privacy Policy to proceed.
                        </p>
                    )}
                    <button
                        className='button_signupshop mt-3'
                        style={{ cursor: 'pointer' }}
                        onClick={handleRequestOTP}
                        disabled={!termsAccepted} // Disable the button if terms are not accepted
                    >
                        REQUEST OTP
                    </button>
                    {/* <button className='button_signupexistinguser mt-3'>
                        Existing User? Login
                    </button> */}
                    {/* Use CSS to position the signup text at the bottom */}
                    <div className='mt-auto'>
                        <p className='mb-2 signupparacolor_shop' style={{ cursor: "pointer" }} onClick={switchToSignup}>New to Rashail Agro? Signup</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Loginform
