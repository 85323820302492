/* global google */
import React, { useEffect } from 'react';
import navbarshop_language from '../Images/Navbar/navbar_language.png'

const GoogleTranslateWidget = () => {
  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);

    window.googleTranslateElementInit = googleTranslateElementInit;

    return () => {
      // Clean up: remove the script and cleanup global function
      document.body.removeChild(addScript);
      delete window.googleTranslateElementInit;
    };
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,hi',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };

  return <div id="google_translate_container" style={{ position: 'relative' }}>
    <img
      src={navbarshop_language} 
      alt="Language Icon"
      className='Language_icon'
      style={{
        position: 'absolute',
        left: '10px', // Adjust as needed to position the image
        top: '50%',
        transform: 'translateY(-50%)',
        width: '20px', // Adjust image size as needed
        height: '20px',
      }}
    />
    <div id="google_translate_element"></div>
  </div>;
};

export default GoogleTranslateWidget;
