import Irrigation from '../Images/Shop/irrigation.png'
import Machinery from '../Images/Shop/machinery.png'
import Accessories from '../Images/Shop/Accessories.png'
import Chemicals from '../Images/Shop/Chemicals.png'
import Fertilizers from '../Images/Shop/Fertilizers.png'
import Organic from '../Images/Shop/Organic.png'
import Automation from '../Images/Shop/Automation.png'
import productslider1 from '../Images/Shop/productslider1.png'
import productslider2 from '../Images/Shop/productslider2.png'
import Gsmmotor_starter from '../Images/Shop/Gsm motor starter.png'
import iotmotor_starter from '../Images/Shop/IoT Motor Starter.png'
import rapidsoil_Tester from '../Images/Shop/Rapid Soil Tester.png'
import iotmushroom_datalogger from '../Images/Shop/IoT Mushroom Da.png'
import IoT_hydroponics from '../Images/Shop/IoT Hydroponics.png'
import Plantliquid_newarrival from '../Images/Shop/Plant Liquid Food.png'
import grassshear_newarrival from '../Images/Shop/Grass Shear.png'
import gardeningtoolk_newarrival from '../Images/Shop/Gardening Toolk.png'
import cocopit_newarrival from '../Images/Shop/Coco-pit.png'
import plantfertilizer_newarrival from '../Images/Shop/Plant Fertilizer.png'
import liquidfertiliser_recentlyview from '../Images/Shop/Liquid Fertilize.png'
import organicfertiliser_recentlyview from '../Images/Shop/Organic Fertilizer.png'
import organicplant_recentlyview from '../Images/Shop/Organic Plant.png'
import neemcaremanu_recentlyview from '../Images/Shop/Neemcare Manu....png'
import organicplantfer_recentlyview from '../Images/Shop/Organic Plant Fer....png'
import review1 from '../Images/Shop/review1.png'
import review2 from '../Images/Shop/review2.png'
import review3 from '../Images/Shop/review3.png'
import review4 from '../Images/Shop/review4.png'
import review5 from '../Images/Shop/review5.png'
import review6 from '../Images/Shop/review6.png'
import nodelevery_shop from '../Images/Shop/No Delivery Charges.png'
import safesecure_shop from '../Images/Shop/Safe & Secure Payments.png'
import qualityagro_shop from '../Images/Shop/Quality Agro-Products.png'
// home product Data
const Topproduct_shop = [
    {
        img: Irrigation,
        name: "Irrigation",

    },
    {
        img: Machinery,
        name: "Machinery",

    },
    {
        img: Accessories,
        name: "Accessories",

    },
    {
        img: Chemicals,
        name: "Chemicals",

    },
    {
        img: Fertilizers,
        name: "Fertilizers",

    },
    {
        img: Organic,
        name: "Organic",

    },
    {
        img: Automation,
        name: "Automation",

    },
];
const Topproductslider_shop = [
    {
        img: productslider1,
    },
    {
        img: productslider2,
    },
    {
        img: productslider1,
    },
    {
        img: productslider2,
    }
];

const IOTdevices_shop = [
    {
        img: Gsmmotor_starter,
        product_name: "GSM Motor Starter",
        price: 3999
    },
    {
        img: iotmotor_starter,
        product_name: "IoT Motor Starter",
        price: 5999
    },
    {
        img: rapidsoil_Tester,
        product_name: "Rapid Soil Tester",
        price: 3999
    },
    {
        img: iotmushroom_datalogger,
        product_name: "IoT Mushroom Da...",
        price: 3999
    },
    {
        img: IoT_hydroponics,
        product_name: "IoT Hydroponics",
        price: 3999
    },
    {
        img: iotmushroom_datalogger,
        product_name: "IoT Mushroom Da...",
        price: 3999
    },
];


const NewArrivals_shop = [
    {
        img: Plantliquid_newarrival,
        product_name: "Plant Liquid Food...",
        subname: "Garden King",
        price: 399,
        discount: 10
    },
    {
        img: grassshear_newarrival,
        product_name: "Grass Shear cu...",
        subname: "Falcon",
        price: 249,
        discount: 10
    },
    {
        img: gardeningtoolk_newarrival,
        product_name: "Gardening Toolk...",
        subname: "JINPRI",
        price: 189,
        discount: 10
    },
    {
        img: cocopit_newarrival,
        product_name: "Coco-pit 9.5 Kg...",
        subname: "GoCoir Products",
        price: 337,
        discount: 20
    },
    {
        img: plantfertilizer_newarrival,
        product_name: "Plant Fertilizer",
        subname: "Agriworld",
        price: 399,
        discount: 30
    },

];

const recentlyview_shop = [
    {
        img: liquidfertiliser_recentlyview,
        product_name: "Liquid Fertilize...",
        subname: "Sparkenzy",
        price: 399,
        discount: 30
    },
    {
        img: organicfertiliser_recentlyview,
        product_name: "Organic Fertilizer...",
        subname: "UGADO",
        price: 249,
        discount: 20
    },
    {
        img: organicplant_recentlyview,
        product_name: "Organic Plant To...",
        subname: "Jeevamroot",
        price: 189,
        discount: 10
    },
    {
        img: neemcaremanu_recentlyview,
        product_name: "Neemcare Manu...",
        subname: "Farmcraft",
        price: 337,
        discount: 30
    },

    {
        img: organicplantfer_recentlyview,
        product_name: "Organic Plant Fer...",
        subname: "Paripoorna Ayurvedic",
        price: 399,
        discount: 20
    },

];

const customerreview = [
    {
        img: review1,
        name: "Vishwas Deshmukh",
        desc: "“Gsm motor starter controller best product of this company”",
        rating: 5,
    },
    {
        img: review2,
        name: "Roshan Patil",
        desc: "“Very nice products by rashail tech labs,They are really having good products”",
        rating: 4.5,
    },
    {
        img: review3,
        name: "Pandurang Mahalle",
        desc: "“They are really having good products.One of their product GSM motor controller is of great use for farmers like us.”",
        rating: 5,
    },
    {
        img: review4,
        name: "Arav Raut",
        desc: "“Very interactive environment.And friendly mentors .I'm grateful for the guidance.”",
        rating: 5,

    },
    {
        img: review5,
        name: "Slok Kirtane",
        desc: "“The people here are full of enthusiasm and innovative ideas.... Who strive to build great products at cheap price and are customer centric...”",
        rating: 5,
    },
    {
        img: review6,
        name: "Supesh Parkhade",
        desc: "“Amazing company  product. Gsm device is working amazing.this product so nice”",
        rating: 4,

    },

];

const bottomshop_data = [
    {
        img: nodelevery_shop,
        name: "No Delivery Charges",

    },
    {
        img: safesecure_shop,
        name: "Safe & Secure Payments",

    },
    {
        img: qualityagro_shop,
        name: "Quality Agro-Products",

    },
];




export { Topproduct_shop, Topproductslider_shop, IOTdevices_shop, NewArrivals_shop, recentlyview_shop, customerreview, bottomshop_data };