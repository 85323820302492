import React, { useEffect, useState } from 'react'
import { NewArrivals_shop, bottomshop_data, recentlyview_shop } from '../Constants/Shopdata'
import gardeningtoolk_newarrival from '../Images/Shop/Gardening Toolk.png'
import addtocart_shopdetails from '../Images/Shopdetails/addtocart_shodetails.png'
import Buynow_shopdetails from '../Images/Shopdetails/Buynow_shopdetails.png'
import axios from 'axios'
import { producturl } from '../Constants/urls'
import ReactImageMagnify from 'react-image-magnify';
import { useDispatch, useSelector } from 'react-redux'
import { add, remove } from '../Redux/cartSlice';
import { Card, Modal, Button } from 'react-bootstrap';
import Buynowform from '../Components/Buynowform'
import Demoform from '../Components/Demoform'
import Footer from '../Components/Footer'


const Shopdetails = () => {
    const url = window.location.href;
    const url1 = window.location.origin;
    const parturl = url.replace(url1, "").replace("/shop/", "");
    const slug = parturl.endsWith("/") ? parturl.replace("/", "") : parturl;
    console.log(slug)
    const dispatch = useDispatch();
    const products = useSelector((state) => state.cart);
    const [product, setproduct] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [productName, setProductName] = useState(product?.product_name || '');
    const [Price, setPrice] = useState(product?.product_price || 0);


    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalOpen1 = () => {
        setShowModal1(true);
    };
    const handleModalClose1 = () => {
        setShowModal1(false);
    };

    const handleAdd = (product) => {
        dispatch(add(product));
    };

    const handleRemove = (product_id) => {
        dispatch(remove(product_id));
    };

    const isInCart = (productId) => {
        return products.some(item => item.product_id === productId);

    };
    const fetchData = async (page = 1) => {
        try {
            //   setLoading(true);
            const token = '228701687f8db711593cf986b7cbe995d1639da3';
            const response = await axios.get(
                `${producturl}?slug=${slug}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            console.log(response);
            setproduct(response.data.results[0]);
        } catch (error) {
            console.error(error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const [selectedImage, setSelectedImage] = useState(null);

    console.log(selectedImage)
    const handleThumbnailHover = (image) => {
        setSelectedImage(image);
    };

    const [technicalDetails, setTechnicalDetails] = useState([]);
    const [additionalDetails, setAdditionalDetails] = useState([]);
    const hasData = technicalDetails.length > 0 || additionalDetails.length > 0;
    useEffect(() => {
        // Fetch and set technical details from API response
        if (product && product.extra_data && product.extra_data.technical_details) {
            setTechnicalDetails(product.extra_data.technical_details);
        }
        if (product && product.extra_data && product.extra_data.additional_details) {
            setAdditionalDetails(product.extra_data.additional_details);
        }
    }, [product]);


    useEffect(() => {
        if (product && product.product_images.length > 0) {
            setSelectedImage(product.product_images[0]);
        }
        if (product) {
            setProductName(product.product_name); // Set productName state
            setPrice(product.product_price); // Set Price state
        }
    }, [product]);
    const [smallImageDimensions, setSmallImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = selectedImage;
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const maxWidth = 400;
            const maxHeight = 400;
            let width = maxWidth;
            let height = maxHeight;

            if (aspectRatio > maxWidth / maxHeight) {
                height = maxWidth / aspectRatio;
            } else {
                width = maxHeight * aspectRatio;
            }

            setSmallImageDimensions({ width, height });
        };
    }, [selectedImage]);

    return (
        <>
            <div className='container-fluid'>
            <div className="row justify-content-center mb-4  d-none d-md-block">
                    <div className="col-md-12 d-flex topproduct_shop" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Irrigation</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Machinery</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Accessories</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Chemicals</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Fertilizers</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Organic</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Automation</p>
                        </div>
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className="row justify-content-center topproduct_shop_withoutscroll" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Irrigation</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Machinery</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Accessories</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Chemicals</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Fertilizers</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Organic</p>
                        </div>
                        <div className="col-3 col-md-2 text-center ">
                            <p className="paraheading_shop_top">Automation</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 justify-content-center mx-1" style={{ backgroundColor: "#ffffff" }}>
                    <div className='col-md-12 col-lg-4 d-flex justify-content-center '>
                        <div className="col-md-12 productimg-productdetails  col-12 text-center" >
                            <div className='text-center selectedimage_resp mx-auto mt-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '400px', position: 'relative', border: "0.5px solid rgba(4, 58, 58, 0.5)" }} >
                                <ReactImageMagnify
                                    {...{
                                        smallImage: {
                                            alt: 'Wristwatch by Ted Baker London',
                                            className: 'Wristwatch by Ted Baker London',
                                            isFluidWidth: false, // Set to false to fix the width
                                            width: smallImageDimensions.width, // Set the width of the image
                                            height: smallImageDimensions.height,
                                            src: selectedImage,

                                        },
                                        largeImage: {
                                            src: selectedImage,
                                            width: 1200,
                                            height: 1200
                                        },
                                        enlargedImageContainerDimensions: {
                                            width: '200%',
                                            height: '150%',

                                        },
                                        enlargedImageContainerStyle: {
                                            backgroundColor: 'white'
                                        }
                                    }}
                                />
                            </div>
                            <div className=" image-container-productdetails d-flex mt-5 mb-5 justify-content-center text-center" style={{ maxWidth: '400px', width: '400px', overflowX: 'scroll', '-ms-overflow-style': 'none', margin: '0 auto' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                    {product && product?.product_images.map((image, index) => (
                                        <div key={index} style={{ minWidth: '120px', maxWidth: '120px', margin: '0 auto' }}>
                                            <img
                                                src={image}
                                                alt={`Thumbnail ${index}`}
                                                className="img-fluid image-thumbnail"
                                                style={{ cursor: 'pointer', border: "0.5px solid rgba(4, 58, 58, 0.5)", width: '100%', height: '70px', objectFit: 'contain' }}
                                                onMouseEnter={() => handleThumbnailHover(image)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* <div className='row d-flex justify-content-center mt-3 mb-3 mx-5'>
                            <div class=" col-md-12 form-group d-flex align-items-center pt-2 pb-2" style={{ backgroundColor: "#ffffff", border: "0.5px solid rgba(4, 58, 58, 0.8)" }}>
                                <label for="quantity-select" class="mr-3 mx-5">Quantity</label>
                                <select class="" id="quantity-select p-2" required style={{ backgroundColor: "rgb(236, 236, 236)", width: "100%" }}>
                                    <option selected>- Select Quantity -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div> */}

                            {product && product.is_enquiry_product ? (
                                <div className='d-flex justify-content-center mt-3 mx-5'>
                                    <div className='buynow_shopdeatails text-center' onClick={handleModalOpen1} style={{ cursor: "pointer" }}>
                                        <span>
                                            <img src={Buynow_shopdetails} alt='' className="img-flui image-thumbnail" />
                                        </span>
                                        <span className='mx-1' style={{ color: "#ffffff", fontWeight: "600" }}>
                                            Request a Demo
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className='d-flex justify-content-between mt-3 '>
                                    {isInCart(product?.product_id) ? (
                                        <div className='addtocart_shopdetails' style={{ cursor: "pointer" }} onClick={() => handleRemove(product.product_id)}>
                                            <span>
                                                <img src={addtocart_shopdetails} alt='' className="img-removecart" />
                                            </span>
                                            <span className='mx-1' style={{ color: "#2FB95D", fontWeight: "600" }}>
                                                Remove from Cart
                                            </span>
                                        </div>
                                    ) : (
                                        <div className='addtocart_shopdetails' style={{ cursor: "pointer" }} onClick={() => handleAdd(product)}>
                                            <span>
                                                <img src={addtocart_shopdetails} alt='' className="img-addtocart" />
                                            </span>
                                            <span className='mx-1' style={{ color: "#2FB95D", fontWeight: "600" }}>
                                                Add to cart
                                            </span>
                                        </div>
                                    )}
                                    <div className='buynow_shopdeatails' style={{ cursor: "pointer" }} onClick={handleModalOpen}>
                                        <span>
                                            <img src={Buynow_shopdetails} alt='' className="img-flui image-thumbnail" />
                                        </span>
                                        <span className='mx-1' style={{ color: "#ffffff", fontWeight: "600" }} >
                                            Buy Now
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="col-md-12 col-lg-7 text-start mt-4 mt-md-4">
                        <div className='text-start'>
                            <div>
                                <h4>{product?.product_name}</h4>
                                {/* <span className='in_stock'>In Stock</span> */}
                                <span className='in_stock'  >
                                    {product && product.is_stock_available ? "In Stock" : "Out of Stock"}
                                </span>

                                {product?.is_enquiry_product ? (
                                    ''
                                ) : (
                                    <>
                                        <div className='mt-2'>
                                            <span className='actualprice_product'>₹{product?.product_price}</span> MRP  <span className='fullprice_product'>
                                                <small style={{ textDecoration: "line-through" }}>
                                                    ₹
                                                    {/* 200 */}
                                                    {product?.product_price + (product?.product_price * (product?.product_discount / 100))}
                                                </small>
                                            </span>
                                            <span style={{ color: '#2FB95D' }}> {product?.product_discount > 0 && (
                                                <span style={{ color: '#2FB95D' }}>{`${product?.product_discount}% Off`}</span>
                                            )}</span>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='productdetailspara mt-2 mb-3 text-start'>
                                <h4>Specifications</h4>
                                <p className='description_productdetails'>
                                    {product?.product_description.split('\n').map((paragraph, index) => (
                                        <React.Fragment key={index}>
                                            {paragraph}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>
                            {hasData && (
                                <>
                                    <h4>General Info</h4>
                                    <div className="row d-flex  mb-2" >
                                        <div className='col-md-12 d-flex'>
                                            <div className="col-md-12 col-12">
                                                <h5>Technical Details</h5>
                                                {technicalDetails.map((detail, index) => (
                                                    <div className="row d-flex  mb-2" key={index}>
                                                        <div className='col-md-12 d-flex'>
                                                            <div className="col-md-6 col-6">
                                                                <span className="technicaldetailshead">{Object.keys(detail)[0]}</span>
                                                            </div>
                                                            <div className="col-md-6 col-6 mx-2">
                                                                <span className="technicaldetailssubhead">{Object.values(detail)[0]}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='col-md-12 d-flex'>
                                            <div className="col-md-12 col-12">
                                                <h5>Additional Details</h5>
                                                {additionalDetails.map((detail, index) => (
                                                    <div className="row d-flex  mb-2" key={index}>
                                                        <div className='col-md-12 d-flex'>
                                                            <div className="col-md-6 col-6">
                                                                <span className="technicaldetailshead">{Object.keys(detail)[0]}</span>
                                                            </div>
                                                            <div className="col-md-6 col-6 mx-2">
                                                                <span className="technicaldetailssubhead">{Object.values(detail)[0]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <h4 className='mt-4'>About this item</h4>
                            <div className="col-md-12 col-sm-12 text-center">
                                <span>
                                    <div className='productdetailspara text-start'>
                                        {product?.meta_data ? (
                                            <ul>
                                                {product.meta_data.split('\n').map((sentence, index) => (
                                                    <li key={index}>{sentence}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #00000033", paddingBottom: "10px" }}>
                        <div>
                            <h4 style={{ marginRight: '10px' }}>New Arrivals</h4>
                            <div>(Top Deals)</div>
                        </div>
                        <div className='viewall_shop' >
                            View All
                        </div>
                    </div>
                    <div className="col-md-12 justify-content-center text-center mt-3 d-flex">
                        {NewArrivals_shop.map((data, index) => (
                            <div key={index} className="testimonial-slide">
                                <div className="card custom-card-style_blog" style={{ width: "14rem", margin: "0 10px" }}>
                                    <div className='card_blog_first'>
                                        <img src={data.img} className="card-img-top" alt="..."
                                            style={{
                                                height: '150px',
                                                display: 'block',
                                                margin: '0 auto',
                                                objectFit: "contain",
                                                padding: "20px"
                                            }}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">{data.product_name}</h5>
                                            <p className="card-text">Just ₹ {data.price}</p>
                                        </div>
                                    </div>
                                    <span
                                        className='readmore_blog text-center mt-2'
                                        style={{ cursor: "pointer" }}
                                    >
                                        Buy Now
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 topproduct_shop">
                    <div className='justify-content-between' style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #00000033", paddingBottom: "10px" }}>
                        <div>
                            <h4 style={{ marginRight: '10px' }}>Recently Viewed</h4>

                        </div>
                        <div className='viewall_shop' >
                            View All
                        </div>
                    </div>
                    <div className="col-md-12 justify-content-center text-center mt-3 d-flex">
                        {recentlyview_shop.map((data, index) => (
                            <div key={index} className="card-wrapper">
                                <div className="card" style={{ width: "14rem", margin: "0 10px" }}>
                                    <img src={data.img} className="card-img-top" alt="..."
                                        style={{
                                            height: '150px',
                                            display: 'block',
                                            margin: '0 auto',
                                            objectFit: "contain",
                                            padding: "20px"
                                        }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.product_name}</h5>
                                        <p className="card-text">Just ₹ {data.price}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container mt-4 mb-4">
                    <div className="row justify-content-start" style={{ borderTop: "1px solid rgba(4, 58, 58, 1)", borderBottom: "1px solid rgba(4, 58, 58, 1)" }}>
                        {bottomshop_data.map((data, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-12" >
                                <div className="text-center bottomshop_background pb-2 mt-5 mb-5">
                                    <img
                                        src={data.img}
                                        alt="product_image"
                                        className="product_image"
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            objectFit: 'contain',
                                            padding: '10px'
                                        }}
                                    />
                                    <p className="text-center" style={{ color: '#ffffff' }}>
                                        {data.name}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={showModal} onHide={handleModalClose}
                    dialogClassName="custom-modal" >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        <Modal.Title className="text-center">Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-12 col-sm-12 text-center mx-auto">
                            <div className='mt-3'>
                                <Buynowform productName={productName} Price={Price} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModal1} onHide={handleModalClose1}
                //  scrollable
                >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-10 col-sm-10 text-center mx-auto">
                            <div className='mt-3'>
                                <Demoform closeModal={handleModalClose} closeModal1={handleModalClose1} productName={productName} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>

    )
}

export default Shopdetails
