import React from 'react'
import { Topproduct_shop } from '../Constants/Shopdata'
import Footer from '../Components/Footer'

const Termscondition = (showFooter) => {
    return (
        <>
            <div className='container-fluid'>
                <div className="row justify-content-center mb-4  d-none d-md-block">
                    <div className="col-md-12 d-flex topproduct_shop" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Irrigation</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Machinery</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Accessories</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Chemicals</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Fertilizers</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Organic</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Automation</p>
                        </div>
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className="row justify-content-center topproduct_shop_withoutscroll" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Irrigation</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Machinery</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Accessories</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Chemicals</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Fertilizers</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Organic</p>
                        </div>
                        <div className="col-3 col-md-2 text-center ">
                            <p className="paraheading_shop_top">Automation</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 ">
                    <div className="col-md-10 p-4 mb-5" style={{ backgroundColor: "#ffffff" }}>
                        <h5 className='text-center shopheading'>Rashail’s Terms of Use</h5>
                        <p className='termscondition_desc'>These terms and conditions outline the rules and regulations for the use of Rashail Agro Bazaar's Website, located at <a className="tems" style={{ color: "#2FB95D" }}>www.rashailagrobazaar.com</a></p>
                        <p className='termscondition_desc'>
                            By accessing this website, we assume you accept these terms and conditions. Do not continue to use Rashail Agro Bazaar if you do not agree to take all of the terms and conditions stated on this page.
                            The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all agreements: "Client", "You," and "Your" refers to you, the person logging in to this website and compliant to the Company’s terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us," refers to our Company. "Party," "Parties," or "Us," refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect to the provision of the Company’s stated services, in accordance with and subject to, prevailing law.
                        </p>
                        <h5 className='text-start shopheading'>License</h5>
                        <p className='termscondition_desc'>
                            Unless otherwise stated, Rashail Agro Bazaar and/or its licensors own the intellectual property rights for all material on Rashail Agro Bazaar. All intellectual property rights are reserved. You may access this from Rashail Agro Bazaar for your own personal use subjected to restrictions set in these terms and conditions.
                        </p>
                        <span>
                            <h5 className='termscondition_subheading'>You must not:</h5>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>Republish material from Rashail Agro Bazaar
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>Sell, rent, or sub-license material from Rashail Agro Bazaar
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>Reproduce, duplicate, or copy material from Rashail Agro Bazaar
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>Redistribute content from Rashail Agro Bazaar
                            </p>
                        </span>
                        <p className='termscondition_desc'>
                            Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Rashail Agro Bazaar does not filter, edit, publish, or review Comments prior to their presence on the website.
                        </p>
                        <p className='termscondition_desc'>
                            Comments do not reflect the views and opinions of Rashail Agro Bazaar, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Rashail Agro Bazaar shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                        </p>
                        <p className='termscondition_desc'>
                            Rashail Agro Bazaar reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.
                        </p>
                        <span>
                            <h5 className='termscondition_subheading'>You warrant and represent that:</h5>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy
                            </p>
                            <p className='termscondition_desc text-start'>
                                <span className="checkbox_termcondition"></span>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            {showFooter && <Footer />}
        </>

    )
}

export default Termscondition
