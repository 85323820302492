import React from 'react'
import { Topproduct_shop } from '../Constants/Shopdata'
import Footer from '../Components/Footer'

const Privacypolicy = (showFooter) => {
    return (
        <>
            <div className='container-fluid'>
                <div className="row justify-content-center mb-4  d-none d-md-block">
                    <div className="col-md-12 d-flex topproduct_shop" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Irrigation</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Machinery</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Accessories</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Chemicals</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Fertilizers</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Organic</p>
                        </div>
                        <div className='text-center justify-content-center mx-auto product-item' >
                            <p className='text-center paraheading_shop_top'>Automation</p>
                        </div>
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className="row justify-content-center topproduct_shop_withoutscroll" style={{ borderBottom: "1px solid #00000033" }}>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Irrigation</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Machinery</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Accessories</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Chemicals</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Fertilizers</p>
                        </div>
                        <div className="col-3 col-md-2 text-center mb-2">
                            <p className="paraheading_shop_top">Organic</p>
                        </div>
                        <div className="col-3 col-md-2 text-center ">
                            <p className="paraheading_shop_top">Automation</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mx-auto mt-4 mb-4 ">
                    <div className="col-md-10 p-4 mb-5" style={{ backgroundColor: "#ffffff" }}>
                        <h5 className='text-center shopheading'>Privacy Policy</h5>
                        <div className='termscondition_desc'>
                            This privacy statement is written to inform our visitors of how we operate. We
                            would like to inform you that whenever you browse our website, we will take
                            all reasonable precautions to keep the information that we gather as secure
                            as we can.
                        </div>
                        <h5 className='text-start shopheading'>1. User’s Assent To The Privacy Statement</h5>
                        <div className='termscondition_desc'>
                            You consent to letting us have access to your information if you abide by
                            our privacy statement. Likewise, let us distribute the information however
                            we see fit. The privacy statement is subject to periodic revisions. In order
                            to see the updated website, please visit us frequently.
                        </div>
                        <h5 className='text-start shopheading'>2. Data Collection</h5>
                        <p className='termscondition_desc'>
                            According to our privacy policy, we gather user data at various points
                            during a visitor’s visit to our website. These points are shown below.
                        </p>
                        <h5 className='text-start optingservice_pc'>Opting Our Services</h5>
                        <p className='termscondition_desc'>
                            Users need to provide their contact information, including
                            name, email address, contact number, location, and area
                            of interest, when they inquire about our services or sign
                            up for our services.
                        </p>
                        <h5 className='text-start optingservice_pc'>Back End Data Collection</h5>
                        <p className='termscondition_desc'>
                            When a user visits our website, we collect data about that
                            user. Additionally, we keep track of each and every
                            activity a visitor takes while on our site. We want to offer
                            our users the greatest possible user experience.
                        </p>
                        <h5 className='text-start shopheading'>3. Purpose of Data Collection</h5>
                        <p className='termscondition_desc'>
                            In order to provide our visitors with better services, we collect and
                            store user data for our partners. Here’s a quick summary of why we
                            gather user data:
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>The first and most important step is to confirm the user’s
                            identification who fills out our website’s contact form or
                            contacts us via live chat or through email subscription.
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>To advertise, do statistical research, conduct client studies,
                            and create client profiles; to provide better services to our
                            users.
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>Analyse market developments and demand.
                        </p>
                        <h5 className='text-start shopheading'>4. Data processing Best Practices</h5>
                        <p className='termscondition_desc'>
                            In compliance with data protection laws, we process user data.
                            Processing our data is necessary for us to offer quality services to our
                            users. We will only process data in accordance with our policy when
                            the user agrees to the conditions of it. Additionally, users have the
                            option to contact us at any time to revoke their consent to receive our
                            data.
                        </p>
                        <h5 className='text-start shopheading'>5. Data Exchange</h5>
                        <p className='termscondition_desc'>
                            We will only share the data we have collected when it is absolutely
                            necessary. Here are the situations in which we share user data with a
                            third party or an individual.
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>Our business partners who provide services to our customers
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>If a government associate or regulatory authority requested it,
                            we need to share the required information.
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>If there is a legal process that we must serve, we must share
                            data.
                        </p>
                        <p className='termscondition_desc text-start'>
                            <span className="checkbox_termcondition"></span>With the user’s consent, we will share data for marketing
                            purposes as long as the user does not object.
                        </p>
                        <h5 className='text-start shopheading'>6. Maintaining Data Confidentially</h5>
                        <p className='termscondition_desc'>
                            In order to maintain confidentiality, we take effective measures to
                            secure user data. We will not allow any individual or third party to
                            access our users’ private information in accordance with our policy.
                            We utilised advanced technology, such as a better server and a larger
                            database, to accomplish this.
                        </p>
                        <h5 className='text-start shopheading'>7. Collecting Browser Cookies</h5>
                        <p className='termscondition_desc'>
                            In order to maintain confidentiality, We take effective measures to
                            secure user data. We will not allow any individual or third party to
                            access our users’ private information in accordance with our policy.
                            We utilised advanced technology, such as a better server and a larger
                            database, to accomplish this.

                        </p>
                        <h5 className='text-start shopheading'>8. Contact Us</h5>
                        <p className='termscondition_desc'>
                            If you’d like to learn more about our privacy policies, we’d be happy to
                            provide you with more information on how we protect and use your
                            personal information. Contact Support for more information.
                        </p>
                    </div>
                </div>
            </div>
            {showFooter && <Footer />}
        </>

    )
}

export default Privacypolicy
