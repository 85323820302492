import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleDecrement, handleIncrement, remove } from "../Redux/cartSlice";
import { useNavigate } from "react-router-dom";
import emptycart_gif from '../Images/Navbar/empty_cart.gif'
import Buynowform from "../Components/Buynowform";
import { Card, Modal, Button } from 'react-bootstrap';
import Buynowformcart from "../Components/Buynowformcart";
import smileface from '../Images/cart/smile-circle-svgrepo-com 1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
import visa_footer from '../Images/Footer/Visa.png'
import mastercart_footer from '../Images/Footer/Mastercard.png'
import paypal_footer from '../Images/Footer/PayPal.png'
import applepay_footer from '../Images/Footer/ApplePay.png'
import googlepay_footer from '../Images/Footer/GooglePay.png'
import discover_footer from '../Images/Footer/Discover.png'
import maestro_footer from '../Images/Footer/Maestro.png'
import Footercart from "../Components/Footercart";

const Cart = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.cart);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [Price, setPrice] = useState(null)
    const [productName, setproductName] = useState(null);
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };
    const handleRemove = (product_id) => {
        dispatch(remove(product_id));
    };

    const handleInc = (product) => {
        console.log("incr");
        dispatch(handleIncrement(product));
    };
    // console.log(products);

    const handleDec = (product) => {
        dispatch(handleDecrement(product));
    };

    const totalPrice = () => {
        let total = 0;
        products.forEach((product) => {
            total += product.product_qty * product.product_price;
        });
        return total.toFixed(2);
    };

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        // product_name: '',
        // description: '',
        address: '',
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // const handleBuyNow = () => {
    //     if (products && products.length > 0) {
    //         const allProductNames = products.map(product => product.product_name).join(', ');
    //         setproductName(allProductNames);
    //         setPrice(totalPrice());
    //         handleModalOpen();
    //     } else {
    //         console.error("No products found");
    //     }
    // };

    const handleBuyNow = () => {
        if (products && products.length > 0) {
            // Construct productName with quantities
            const productNameWithQuantities = products.map(product => {
                return {
                    name: product.product_name,
                    quantity: product.product_qty.toString()
                };
            });
            setproductName(JSON.stringify(productNameWithQuantities));
            setPrice(totalPrice());
            handleModalOpen();
        } else {
            console.error("No products found");
        }
    };

    const handleplaceorder = async () => {
        navigate('/deliveryadress');
    };


    return (
        <>
            {/* <HeadProvider>
                <Title>Smart Farming Solutions by Rashail Agro</Title>
                <Meta name="description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <base target="_blank" href="https://rashailagro.in/cart/" />
                <HeadLink rel="canonical" content="https://rashailagro.in/cart/" />
                <Meta name="keywords" content="smart farming, precision farming, new technologies in agriculture, iot in agriculture, internet of things in agriculture, farming in india, farming app, farming and agriculture, farmer tools, farmer in agriculture, farm equipment, artificial intelligence in agriculture, ai in agriculture, agriculture technology, agriculture startups in india, agriculture in iot, agriculture in india, agriculture farming, agriculture equipment machine, agriculture equipment list, agriculture equipment, agriculture department, agriculture app, agriculture and technology, agricultural services, agricultural products, agricultural machinery, agriculture, agricalchar farm, agri tech, agri startups in india, crop management solution, iot smart agriculture" />
                <Meta property="og:title" content="Smart Farming Solutions by Rashail Agro" />
                <Meta property="og:description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <Meta property="og:url" content="https://rashailagro.in/cart/" />
                <Meta property="og:type" content="website" />
                <Meta name="twitter:title" content="Smart Farming Solutions by Rashail Agro" />
                <Meta name="twitter:description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <Meta property="twitter:url" content="https://rashailagro.in/cart/" />
                <Meta property="twitter:type" content="website" />
            </HeadProvider> */}
            <div>
                {products.length === 0 ? (
                    <div className="container text-center mt-5">
                        <h2>No products available in cart</h2>
                        <div className="row mt-5 mb-2 justify-content-center">
                            <div class="col-md-12 text-center">
                                <img src={emptycart_gif} alt="best_farming_app_fasalam" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="container mb-5 mt-5">
                            <div className="row justify-content-center" >
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table" id="myTbl">
                                            <thead className="custom-table-head" style={{ backgroundColor: "#ffffff" }}>
                                                <tr className="mt-3">
                                                    <th scope="col">S.No</th>
                                                    <th>Images</th>
                                                    <th scope="col"><span className="px-5 ">Product Name</span></th>
                                                    <th scope="col"><span className="px-5">Price</span></th>
                                                    <th scope="col"><span className="px-5">Quantity</span></th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody className="custom-table-body table-gap" style={{ backgroundColor: "#ffffff" }}>
                                                {products && products.map((product, index) => {
                                                    const serialNumber = index + 1;
                                                    return (
                                                        <tr key={index} className="mt-3" style={{ height: "120px" }}>
                                                            <td scope="row">{serialNumber}</td>
                                                            <td >
                                                                <img
                                                                    src={product.product_images[0]}
                                                                    className="card-img-top imgproduct rounded img-fluid"
                                                                    alt="..."
                                                                    style={{ maxWidth: '100px', maxHeight: '100px', width: '100%', height: 'auto', objectFit: "contain" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {product.product_name.slice(0, 30)}
                                                                <br />
                                                                {product.product_name.slice(30)}
                                                            </td>
                                                            <td > <span className="actualprice_shop">₹ {product.product_price}</span></td>
                                                            <td>
                                                                <div className="button-container">
                                                                    <button className="round-button" type="button" onClick={() => { handleDec(product); }}>
                                                                        -
                                                                    </button>
                                                                    <div className="quantity-display">
                                                                        {product.product_qty}
                                                                    </div>
                                                                    <button className="round-button" type="button" onClick={() => { handleInc(product); }}>
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td> ₹{product.product_price * product.product_qty}</td>
                                                            <td>
                                                                {/* <button
                                                                    className="btn button-cart"
                                                                    onClick={() => handleRemove(product.product_id)}
                                                                >
                                                                   <i className="fa-regular fa-trash-alt" style={{ cursor: 'pointer', color: 'red' }}></i>
                                                                </button> */}
                                                                <button className="btn button-cart" onClick={() => handleRemove(product.product_id)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt} style={{ color: "rgba(244, 44, 0, 1)" }} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-5 " >
                            <div className="row justify-content-center mx-auto " style={{ backgroundColor: "#ffffff" }}>
                                <div class="col-md-12 ">
                                    <p className="text-start price_detailscart">Price Details</p>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p >MRP Total</p>
                                        <p >₹{totalPrice()}</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p >Additional Discount</p>
                                        <p >₹0</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px dashed  #000" }}>
                                        <p >Shipping Charges</p>
                                        <p >₹0</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p >Total Payable</p>
                                        <p >₹{totalPrice()}</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                        {/* <button className="blue-button-cart"
                                            onClick={() => handleBuyNow()}
                                        >Buy Now</button> */}
                                        {/* <button className="btn buttonfunctionaity mb-md-2 mb-2"
                                            onClick={() => handleBuyNow()}
                                        >
                                            Place Order
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-5" >
                            <div className="row justify-content-center">
                                <div class="col-md-12 ">
                                    <span className="cart_paralargeheading text-start">Rashail Automations is a technology platform to facilitate transaction of business. The products and services are offered for sale by the sellers. The user authorizes the delivery personnel to be his agent for delivery of the goods. For details read <a className="tems" style={{ color: "#2FB95D" }}>Terms & Conditions</a> </span>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-5" >
                            <div className="row justify-content-end">
                                <div class="col-md-12 text-end">
                                    <button className="btn buttonfunctionaity mb-md-2 mb-2"
                                        // onClick={() => handleBuyNow()}
                                        onClick={() => handleplaceorder()}
                                    >
                                        Place Order
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-5" >
                            <div className="row justify-content-center">
                                <div class="col-md-12 text-center">
                                    <img src={smileface} alt="best_farming_app_fasalam" className="img-fluid" />
                                    <div className="geniuneproduct_cart mt-3">100% Geniune Agro Products</div>
                                </div>
                            </div>
                        </div>

                    </>
                )}

                <Modal
                    size="lg"
                    show={showModal} onHide={handleModalClose}
                    //  scrollable
                    dialogClassName="custom-modal" >
                    <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                        <Modal.Title className="text-center">Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="col-md-12 col-sm-12 text-center mx-auto">
                            <div className='mt-3'>
                                <Buynowformcart productName={productName} Price={Price} onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
            <Footercart />
        </>
    );
};

export default Cart;
