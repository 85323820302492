import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footerlogo from '../Images/Footer/Rashail Agro LOGO Footer Logo.png'
import Footerplaystore from '../Images/Footer/fasalam_farming_app_download_appstore.png'
import googleplay from '../Images/Footer/fasalam_farming_app_download_google_play.png'
import { Card, Modal, Button } from 'react-bootstrap';
import { FaXTwitter } from "react-icons/fa6";
import Demoformhome from './Demoformhome';
import visa_footer from '../Images/Footer/Visa.png'
import mastercart_footer from '../Images/Footer/Mastercard.png'
import paypal_footer from '../Images/Footer/PayPal.png'
import applepay_footer from '../Images/Footer/ApplePay.png'
import googlepay_footer from '../Images/Footer/GooglePay.png'
import discover_footer from '../Images/Footer/Discover.png'
import maestro_footer from '../Images/Footer/Maestro.png'

const Footer = () => {
    const [email, setEmail] = useState('');
    const handleSubmit = async () => {
        try {
            const response = await fetch('https://sheetdb.io/api/v1/z739dd47c6f71', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (response.ok) {
            } else {
                const responseBody = await response.json();
                console.error('Error submitting form data:', responseBody);
            }
        } catch (error) {
            console.error('An unexpected error occurred', error);
        }
    };




    const handleIconClick = () => {
        handleSubmit();
    };

    const DivStyle = {
        backgroundColor: '#043A3A',
    };

    const elevateStyle = {

    };

    const [showModal, setShowModal] = useState(false);
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
        window.dataLayer.push({ 'event': 'button-click' });
    };




    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };

    const handleDownloadClick = () => {
        window.open('https://apps.apple.com/in/app/fasalam/id6478108954', '_blank');
    };


    const [productName, setproductName] = useState('Home enquiry');
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: productName,
        // description: '',
        address: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        product_name: '',
        // description: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmitform = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    email: '',
                    address: ''
                });
                handleModalClose();
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const handleDownloadClick_android = () => {
        window.open('https://play.google.com/store/apps/details?id=com.rashailagro.fasalam', '_blank');
    };

    return (
        <>

            <div className="container-fluid justify-content-center">
                <div className="row justify-content-center" style={DivStyle}>
                    <div className="col-md-10 col-sm-10  ">
                        <div className="row ">
                            <div className='col-md-8'>
                                <div className='d-flex flex-wrap'>
                                    {/* About Column */}
                                    <div className="col-md-4  col-6 col-sm-4 mb-md-4  px-2 mt-2 mt-md-5 ">
                                        <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none', textAlign: 'start' }}>About</p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                    Contact us
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                    About us
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                    Careers
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                    Blog
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Group Companies Column */}
                                    {/* <div className="col-md-3 col-6 col-sm-3 mb-md-4  px-1 mt-md-5 ">
                                        <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none', textAlign: 'start' }}>Group Companies</p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Rashail Agro
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Rashail Tech labs
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Rashail Automations
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Rashail Infotech
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Platforuma
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {/* Help Links Column */}
                                    <div className="col-md-4 col-6 col-sm-4 mb-md-4  px-1 mt-md-5 ">
                                        <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>Help</p>
                                        <ul className="list-unstyled mb-0">

                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Payments
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Shipping
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Cancellation & Returns
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    FAQ
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Consumer Policy Links Column */}
                                    <div className="col-md-4 col-6 col-sm-4 mb-md-4  px-1 mt-md-5 ">
                                        <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>Consumer Policy</p>
                                        <ul className="list-unstyled mb-0">

                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="/termscondition" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Terms Of Use
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="/privacypolicy" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="#" className="text-gray hover-white  footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Sitemap
                                                </Link>
                                            </li>
                                            <li className="py-1" style={{ textAlign: 'start' }}>
                                                <Link to="/ordercancellation" className="text-gray hover-white footerlinkheading" style={{ color: 'white', textDecoration: 'none' }} onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>

                                                    Cancellation & Returns
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Follow Us  */}
                                <div className='col-md-12 d-block mx-md-2' >
                                    <div className="mt-3 followfooter">
                                        <p className=" letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>
                                            Follow Us
                                        </p>
                                        <div className="mt-2">
                                            <a href=" https://www.facebook.com/rashailagro/" target="_blank" alt="rashail-agro-facebook" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-facebook facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href="https://www.instagram.com/rashailagro/" target="_blank" alt="rashail-agro-instagram" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-instagram facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href="https://twitter.com/RashailAgro" target="_blank" alt="rashail-agro-twitter" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span className="" >
                                                    {/* <img src={twitterlogo} className='twitter_logo' alt="Twitter" style={{ width: '20px', height: '20px', marginRight: '20px',marginBottom:"8px", cursor: 'pointer' }} /> */}
                                                    <FaXTwitter className='facebook-mobile twitter_footer' style={{ color: '#fff', fontSize: '22px', marginRight: '20px', cursor: "pointer", marginBottom: "10px" }} />

                                                </span>
                                            </a>
                                            <a href="https://www.youtube.com/channel/UCiyVvtJtp-MPyYWgDBHXKRw" target="_blank" alt="rashail-agro-youtube" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-youtube-play facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                            <a href=" https://www.linkedin.com/showcase/rashail-agro/" target="_blank" alt="rashail-agro-linkedin" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                                <span><i className="fa fa-linkedin facebook-mobile" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex mt-5 mb-2' style={{ borderLeft: '0.5px solid #9FAFA4' }}>
                                <div className="col-md-12">
                                    <ul className="list-unstyled mb-0">
                                        <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>Office Address</p>
                                        <li className="py-2 footer-address" style={{ color: 'white', textAlign: 'start' }}>
                                            S1,Malwa Tower, Near ICICI Bank, Ashok Nagar, Indore,
                                            Madhya Pradesh - 452001
                                        </li>
                                        <div className='row'>
                                            <div className='col-md-12 justify-content-between d-flex mx-auto'>
                                                <div className='col-md-6'>
                                                    <p className="fs-16 mb-1 mt-1 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>Mail Us</p>
                                                    <li className="py-2 footer-address" style={{ color: 'white', textAlign: 'start' }} >
                                                        <a href="tel:+918349503619" alt="rashail-agro-mobilenumber" style={{ color: 'white', textDecoration: "none" }}>rashailagro@gmail.com</a>
                                                    </li>
                                                </div>
                                                <div className='col-md-6 mx-md-4'>
                                                    <p className="fs-16 mb-1 mt-1 letter-spacing-05 footerhead" style={{ color: '#9FAFA4', textDecoration: 'none' }}>Contact Us</p>
                                                    <li className="py-2 footer-address" style={{ color: 'white', textAlign: 'start' }} >
                                                        <a href="tel:+918349503619" alt="rashail-agro-mobilenumber" style={{ color: 'white', textDecoration: "none" }}>+91 83495 03619</a>
                                                    </li>
                                                </div>

                                            </div>

                                        </div>

                                    </ul>

                                </div>

                            </div>
                        </div>

                    </div>
                    {/* Copyright Section */}
                    <div className="row  justify-content-center d-none d-md-block" style={{ borderTop: '0.5px solid #9FAFA4' }}>
                        <div className="col-md-12 col-sm-10 justify-content-center copyright-mobile-footer text-end" style={{ color: '#FBFBFB', fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px' }}>
                            <div className="row justify-content-center mt-2 mb-2 mb-md-2">
                                <div className="col-md-5 col-sm-5 copyright-mobile-footer text-start" >
                                    <span className='footer-copyright'> @ All Copyright Reserved by Rashail Agro 2024.</span>
                                </div>
                                <div className="col-md-5 col-sm-5 copyright-mobile-footer text-end" >
                                    <img src={visa_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={mastercart_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={paypal_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={applepay_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={googlepay_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={discover_footer} alt="closed_img" className='closed_img visacart_footer' />
                                    <img src={maestro_footer} alt="closed_img" className='closed_img visacart_footer' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* for mobile view -------------- */}
                    <div className="row  justify-content-center d-block d-md-none" style={{ borderTop: '0.5px solid #9FAFA4' }}>
                        <div className="col-md-10 col-sm-10 justify-content-center copyright-mobile-footer text-end" style={{ color: '#FBFBFB', fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px' }}>
                            <div className="row justify-content-between mt-2 mb-2 mb-md-2">
                                <div className="col-md-12 copyright-mobile-footer text-start " >
                                    <span className='footer-copyright'> @ All Copyright Reserved by Rashail Agro 2024.</span>
                                </div>
                                <div className="col-md-12 mt-2 copyright-mobile-footer text-start " >
                                    <img src={visa_footer} alt="closed_img" className='closed_img' />
                                    <img src={mastercart_footer} alt="closed_img" className='closed_img ' />
                                    <img src={paypal_footer} alt="closed_img" className='closed_img ' />
                                    <img src={applepay_footer} alt="closed_img" className='closed_img ' />
                                    <img src={googlepay_footer} alt="closed_img" className='closed_img ' />
                                    <img src={discover_footer} alt="closed_img" className='closed_img ' />
                                    <img src={maestro_footer} alt="closed_img" className='closed_img ' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose}
            >
                <Modal.Header className='mt-2' closeButton style={{ borderBottom: "none" }}>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="col-md-10 col-sm-10 text-center mx-auto">
                        <Demoformhome onSubmit={handleSubmitform} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Footer;
